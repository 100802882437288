import {
  IonContent,
  IonList,
  IonPage,
  useIonAlert,
  useIonLoading,
} from "@ionic/react";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router";
import Input from "../components/Input";
import Button from "../components/Button";
import { AuthLayout } from "../styles/AuthLayout";

import Header from "../components/Header";
import { confirmOtpToken } from "../services";
import { AuthContext } from "../context/AuthContext";

const Otp: React.FC = () => {
  const history = useHistory();
  const { state, dispatch } = useContext(AuthContext);
  const [otp, setOtp] = useState("");
  const [presentLoading, dismissLoading] = useIonLoading();
  const [presentAlert] = useIonAlert();


  const handleGoBack = () => {
    history.push("/");
  }
  const handleOnSubmit = async () => {
    presentLoading({
      message: "Loading...",
      backdropDismiss: false
    });

    try {
      await confirmOtpToken({ phone: state.phone, token: otp });
      dispatch({ type: 'COMPLETED_CONFIRMATION' });
      dismissLoading();
      history.replace('/');
      
    } catch(err) {
      dismissLoading();
      presentAlert({ message: "Unable to confirm token", buttons: ['ok'], backdropDismiss: true });
    }
  }

  return (
    <IonPage>
      <IonContent>
        <Header title="" onClick={handleGoBack} />
        <AuthLayout>
          <h1 className="header">SMS Verfication 🤙🏿 </h1>
          <p className="excerpt">A One-Time Password has been sent to your phone number</p>
          <IonList lines="none">

          <Input
              placeholder="Input OTP"
              type="text"
              onChange={(e) => setOtp((e.target as HTMLInputElement).value)}
            ></Input>

            <div className="spaces"></div>

            <Button title="Confirm Token" onClick={handleOnSubmit}></Button>
          </IonList>
        </AuthLayout>
      </IonContent>
    </IonPage>
  );
};

export default Otp;
