import React, { ChangeEventHandler } from "react";
import { InputStyled } from "../styles/components/Input";

interface InputProps {
  onChange: ChangeEventHandler;
  placeholder: string;
  type?: string;
  value?: string;
  disabled ?: boolean;
}

const Input: React.FC<InputProps> = ({ onChange, placeholder, type, value, disabled }) => {
  return (
    <InputStyled>
      <input
        placeholder={placeholder}
        className="input"
        type={type}
        value={value}
        disabled={disabled}
        onChange={onChange}
      ></input>
      <div>

      </div>
    </InputStyled>
  );
};

export default Input;
