import React, { ChangeEventHandler, MouseEventHandler } from "react";
import { LOGISTICSTYPES } from "../constant/constant";
import { AddressCollectionView } from "../styles/components/addresscollection";
import Button from "./Button";
import Input from "./Input";

interface AddressCollectionProps {
  onPickupAddressChange: ChangeEventHandler;
  onDeliveryAddressChange: ChangeEventHandler;
  type: LOGISTICSTYPES;
  onButtonClick: MouseEventHandler;
}

const AddressCollection: React.FC<AddressCollectionProps> = ({
  onPickupAddressChange,
  onDeliveryAddressChange,
  type,
  onButtonClick
}) => {

  return (
    <AddressCollectionView>
      <h5>Input Addresses</h5>
      <div className="spaces" />

      {type === LOGISTICSTYPES.PICKUP_AND_DELIVERY ||
      type === LOGISTICSTYPES.PICKUP_ONLY ? (
        <Input placeholder="Pickup Address" onChange={onPickupAddressChange} />
      ) : (
        <></>
      )}

      {type === LOGISTICSTYPES.PICKUP_AND_DELIVERY ||
      type === LOGISTICSTYPES.DELIVERY_ONLY ? (
        <>
          <Input
            placeholder="Delivery Address"
            onChange={onDeliveryAddressChange}
          />
        </>
      ) : (
        <></>
      )}

      <Button onClick={onButtonClick} title="Continue" />
    </AddressCollectionView>
  );
};

export default AddressCollection;
