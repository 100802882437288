import styled from "styled-components";

export const BillingLayout = styled.div`
  .footer-custom {
    background-color: #fff;
    padding: 16px;

    .price-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        color: #000;
      }
    }
  }
  .main-content {
    padding: constant(safe-area-inset-top) constant(safe-area-inset-right) constant(safe-area- inset-bottom) constant(safe-area-inset-left);

    background-color: #f9f8f9;
    padding-top: 20px;
    padding-bottom: 100px;

    // height: 100%;

    .promocode {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      margin: 15px;
      background-color: white;
      border-radius: 7px;
      box-shadow: 2px 1px 1px 1px rgba(132,174,45,0.03);

      .promocode-btn {
        padding: 10px;
        border-radius: 5px;
        font-size: 12px;
        color: #fff;
        background-color: #84ae2d;
      }
    }
  }
  

  .item-promo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    margin: 15px;
    background-color: white;
    border-radius: 7px;
    box-shadow: 2px 1px 1px 1px rgba(132,174,45,0.03);

    .sub {
      color: #d7d7d7;
      font-size: 12px;
      margin: 0px;
      padding-bottom: 2px;
    }
    .main {
      font-size: 16px;
      margin: 0px;
    }
    .date {
      display: flex;
      background-color: #84ae2d;
      height: 50px;
      width: 50px;
      color: #fff;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
    }
    

    .date-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  .select-promo {
    padding: 16px;
    p {
      font-size: 14px;
      color: #d7d7d7;
    }
  }
`;