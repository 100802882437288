import { IonContent, IonList, IonItem, IonPage, useIonLoading, useIonAlert } from '@ionic/react';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import Input  from '../components/Input';
import Button from '../components/Button';
import { AuthLayout } from "../styles/AuthLayout";

import logo from "../assets/images/logo.png";
import { AuthContext } from '../context/AuthContext';
import { loginService  } from '../services';

const Login: React.FC = () => {
  const { dispatch } = useContext(AuthContext);
  const [presentLoading, dismissLoading] = useIonLoading();
  const [presentAlert ] = useIonAlert();
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handlEmailChange = (value: any) => {
    setEmail((value.target as HTMLInputElement).value);
  }

  const handlePasswordChange = (value: any) => {
    setPassword((value.target as HTMLInputElement).value);
  }

  /**
   * Login Button Has been clicked
   * Authenticate with server
   * Proceed to set auth state
   */
  const handleOnSubmit = async () => {
    presentLoading({ message: "Loading...", backdropDismiss: false });

    try {
      const response = await loginService({ email, password });

      dispatch({
        type: "SET_LOGIN",
        payload: {
          userId: response['data']['user_id'],
          token: response['token'],
          userDetails: response['data'],
        }
      });

      history.push('/');
      dismissLoading();
    } catch(err) {
      dismissLoading();
      presentAlert({ message: "Invalid Credentials Provided", buttons: ['ok'], backdropDismiss: true });
    } 

  }

  const handleResetPasswordClick = () => {
    history.push('reset-password');
  }

  return (
    <IonPage>
      <IonContent>
        <AuthLayout>
        <img src={logo} alt="logo"></img>
          <h1 className="header">Welcome back 👋🏽</h1>
          <IonList lines="none">

            <Input placeholder="Email Address | Phone Number" type="text" onChange={handlEmailChange}></Input>
            <Input placeholder="Password" type="password" onChange={handlePasswordChange}></Input>

            <div onClick={handleResetPasswordClick} className="w-100">
              <p className="resetPassword">Reset Password</p>
            </div>


            <div className="spaces"></div>

            <Button disabled={(email === '' || password === '')} title="Log In" onClick={handleOnSubmit}></Button>

            <br />
            <IonItem  routerLink='/register'>
              <p>Don't have an account? <strong className="createAccount">Create an Account </strong></p>
            </IonItem>
          

          </IonList>
        </AuthLayout>
      </IonContent>
    </IonPage>
  )
}

export default Login;