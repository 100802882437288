

import { IonContent, IonPage, useIonAlert, useIonLoading, useIonToast } from "@ionic/react";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router";
import Button from "../components/Button";
import Header from "../components/Header";
import Input from "../components/Input";
import { AuthContext } from "../context/AuthContext";
import { changePassword  } from "../services";
import { AuthLayout } from "../styles/AuthLayout";

const ChangePassword: React.FC = () => {
  const history = useHistory();
  const { state } = useContext(AuthContext);

  const [presentToast ] = useIonToast();
  const [presentLoading, dismissLoading] = useIonLoading();
  const [presentAlert ] = useIonAlert();

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");



  const submit = async () => {
    if (currentPassword === "" && currentPassword === null) {
      presentToast({
        message: "All fields are required",
        duration: 3000,
      });
      return;
    }

    if (newPassword === "" && newPassword === null) {
      presentToast({
        message: "All fields are required",
        duration: 3000,
      });
      return;
    }

    if (confirmPassword === "" && confirmPassword === null) {
      presentToast({
        message: "All fields are required",
        duration: 3000,
      });
      return;
    }

    presentLoading({
      message: "Loading...",
      backdropDismiss: false,
    });

    try {
      let data = {
        old_password: currentPassword,
        new_password: newPassword,
        unique_id: state.userDetails.user_id
      };

      await changePassword(data);
      setNewPassword("");
      setConfirmPassword("");
      setCurrentPassword("");
      presentAlert({ message: "Updated Successfully", buttons: ['ok'] });
      dismissLoading();
    } catch(err) {
      dismissLoading();
      presentAlert({
        subHeader: "Error!",
        message: "An error occured, updating profile",
        buttons: ['ok']
      });
    }

  };

  return (
    <AuthLayout>
      <IonPage>
        <IonContent>
          <Header title="Change Password" onClick={() => history.goBack()} />

          <div className="content">
            <Input
              placeholder="Current Password"
              type="password"
              onChange={(e) =>
                setCurrentPassword((e.target as HTMLInputElement).value)
              }
            ></Input>
            <br />

            <Input
              placeholder="New Password"
              type="password"
              onChange={(e) =>
                setNewPassword((e.target as HTMLInputElement).value)
              }
            ></Input>
            <br />

            <Input
              placeholder="Confirm new Password"
              type="password"
              onChange={(e) =>
                setConfirmPassword((e.target as HTMLInputElement).value)
              }
            ></Input>
            <br />

            <Button
              title="Update Password"
              onClick={() => submit()}
            ></Button>
          </div>
        </IonContent>
      </IonPage>
    </AuthLayout>
  );
};

export default ChangePassword;
