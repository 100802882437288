import React from "react";
import { OrderTileModel } from "../models/orderTile.model";
import { OrderTileStyle } from "../styles/components/OrderTile";
import { getTotalString } from "../utils/gettotalString";

const OrderTile: React.FC<OrderTileModel> = ({
  wash_only,
  wash_dry,
  wash_dry_iron,
  createdAt,
  Transaction,
  paid_for,
  onClick
}) => {
  return (
    <OrderTileStyle>
      <div className="wrapper" onClick={() => {
        if(onClick) onClick();
      }}>
        <div className="date-wrapper">
          <div className="date">{new Date(createdAt).getDate()}</div>
          <p className="date-details">
            {new Date(createdAt).getMonth()} -{" "}
            {new Date(createdAt).getFullYear()}
          </p>
        </div>

        <div className="summary">
          <p>Summary</p>
          {wash_only > 0 && <p className="sub">{wash_only} Wash only loads</p>}

          {wash_dry > 0 && <p className="sub">{wash_dry} Wash & dry loads</p>}

          {wash_dry > 0 && <p className="sub">{wash_dry_iron} Wash dry, & iron loads</p>}

          {Transaction != null && (
            <span>
              <p>Total {getTotalString(Transaction.amount)}</p>
              <p>
                {paid_for === 0 ? (
                  <span className="warn">Pending</span>
                ) : (
                  <span className="completed">Paid</span>
                )}
              </p>
            </span>
          )}
        </div>
      </div>
    </OrderTileStyle>
  );
};

export default OrderTile;
