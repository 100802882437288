import React, { MouseEventHandler } from 'react';

import { StyledButton } from "../styles/components/button";

interface ButtonProps {
  title: string;
  onClick: MouseEventHandler;
  disabled ?: boolean;
}
const Button: React.FC<ButtonProps> = ({title, onClick, disabled}) => {

  return (
    <StyledButton>
          <button disabled={disabled} className="custom-btn" onClick={onClick}>
      {title}
    </button>
    </StyledButton>

  );
}

export default Button;