import React from "react";
import { EmptyStateStyled } from "../styles/components/emptyState";
import empty from "../assets/images/empty.png";
import Button from "./Button";

interface EmptyStateProps {
  onClick: Function;
  title: string;
  message ?: string;
  showButton: boolean;
  buttonTitle ?: string;
};

const EmptyState: React.FC<EmptyStateProps>= ({title, message, showButton, onClick, buttonTitle }) => (
  <EmptyStateStyled>
    <div className="box">
      <img src={empty} className="image" alt="empty" />

      <h3 className="title">{title}</h3>
      <p className="message">{message}</p>

      {showButton && (<Button title={buttonTitle ?? ''} onClick={() => onClick()}></Button>)}
    </div>

  </EmptyStateStyled>
);

export default EmptyState;