import { IonContent, IonPage, useIonAlert, useIonLoading } from "@ionic/react";
import React, { useContext } from "react";
import { useHistory } from "react-router";
import Button from "../components/Button";
import Header from "../components/Header";
import { PAYMENTTYPES } from "../constant/constant";
import { BillingContext } from "../context/BillingContext";
import { createTransaction } from "../services";

const BankTransfer: React.FC = () => {
  const history = useHistory();
  const [presentLoading, dismissLoading] = useIonLoading();
  const [presentAlert ] = useIonAlert();

  const { state } = useContext(BillingContext);

  const handleGoBack = () => {
    history.goBack();
  };

  const completeBankTransferOrder = async () => {
    presentLoading({ message: "Finalizing...", backdropDismiss: false });

    try {
      const body = {
        estimated_sum: state.total,
        payment_type: PAYMENTTYPES.BANK_TRANSFER,
        wash_id: state.wash_id,
        status: "active",
      };
      await createTransaction(body);
      dismissLoading();
      // On Success
      history.replace("/home");
    } catch (err) {
      dismissLoading();
      presentAlert({
        message: "Unable to complete transaction",
        buttons: [
          "Cancel",
          {
            text: "Retry",
            handler: () => completeBankTransferOrder(),
          },
        ],
        backdropDismiss: false,
      });
    }
  };

  return (
    <IonPage>
      <IonContent>
        <Header title="Bank Transfer" onClick={handleGoBack}></Header>
        <div style={{ padding: "16px" }}>
          Please do a transfer to account number
          <br />
          <p>
            Account Number: <strong>0478173001</strong>
          </p>
          <p>
            Account Name: <strong>Folawej Global Services Limited </strong>
          </p>
          <p>
            Bank Name: <strong>GTBank</strong>{" "}
          </p>
          <br />
          Your Laundry will be confirmed once your transfer is received.
          <br />
          <div style={{ paddingTop: "40px" }}>
            <Button
              title="Complete"
              onClick={() => completeBankTransferOrder()}
            ></Button>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default BankTransfer;
