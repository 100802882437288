import {
  IonContent,
  IonList,
  IonPage,
  useIonToast,
  useIonLoading,
  useIonAlert,
} from "@ionic/react";
import React, { useState } from "react";
import { useHistory } from "react-router";
import Input from "../components/Input";
import Button from "../components/Button";
import { AuthLayout } from "../styles/AuthLayout";

import Header from "../components/Header";
import { getRecoveryOtp } from "../services";

const ResetPassword: React.FC = () => {
  const history = useHistory();
  const [phone, setPhone] = useState("");
  const [presentToast] = useIonToast();
  const [presentLoading, dismissLoading] = useIonLoading();
  const [presentAlert] = useIonAlert();

  const handlePhoneChange = (value: any) => {
    setPhone((value.target as HTMLInputElement).value);
  };

  /**
   * Login Button Has been clicked
   */
  const handleOnSubmit = async () => {
    if (phone === "" ) return presentToast({ message: "Phone number cannot be null", duration: 3000 });

    presentLoading({ message: "Loading...", backdropDismiss: false });
    
    try {
      const response = await getRecoveryOtp(phone);
      console.log(response);
      presentAlert({
        subHeader: "Success",
        message: response.message,
        buttons: [
          {
            text: "Proceed",
            handler: () => goToAcceptOTP()
          }
        ]
      })
      dismissLoading();
    } catch(err: any) {
      dismissLoading();
      presentAlert({ subHeader: "Error!", message: "This phone number doesn't exist in our records"});
    }    
  };

  const goToAcceptOTP = async () => {
    history.push('/accept-otp/' + phone );
  }

  const handleGoBack = () => {
    history.goBack();
  }

  return (
    <IonPage>
      <IonContent>
        <Header title="" onClick={handleGoBack} />
        <AuthLayout>
          <h1 className="header">Reset Your Password 🤙🏿 </h1>
          <p className="excerpt">Enter your account's phone number to receive an OTP to reset your password.</p>
          <IonList lines="none">


            <Input
              placeholder="Phone Number"
              type="text"
              onChange={handlePhoneChange}
            ></Input>

            <div className="spaces"></div>

            <Button title="Reset Password" onClick={handleOnSubmit}></Button>
          </IonList>
        </AuthLayout>
      </IonContent>
    </IonPage>
  );
};

export default ResetPassword;
