import styled from "styled-components";

export const OrderTileStyle = styled.div`
  .wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    margin: 15px;
    background-color: white;
    border-radius: 7px;
    box-shadow: 2px 1px 1px 1px rgba(132,174,45,0.03);
  }

  
  .sub {
    font-size: 12px;
  }
  
  .summary {
    padding-left: 40px;
  }

  .date {
    display: flex;
    background-color: #84ae2d;
    height: 50px;
    width: 50px;
    color: #fff;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
  }
  
  .dat

  .date-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;