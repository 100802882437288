import styled from "styled-components";

export const StyledHeader = styled.div`
  .wrapper {
    height: 35px;
    width: 35px;
    margin: 10px;
    padding: 6px;
    margin-top: 15px;
    background-color: #84ae2d;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-start;
  }

  .empty-box {
    height: 35px;
    width: 35px;
  }

  .block-ios {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    padding-top: 35px;
    padding-bottom: 15px;
  }

  .block-android {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  h1 {
    text-align: center;
    align-self: center;
    font-size: 18px;
    font-weight: 600;
    // margin-left: 30px;
    display: block;
    color: ${({theme}) => theme.primary}
  }

  .icon {
    color: #fff;
    text-align: center;

  }

`;
