import React, { createContext, useEffect, useReducer } from  "react";
import { Promocode } from "../models/promocode.model";


/**
 * Initial state of user
 */
 const initialState = {
  codes: [],
}

let PromocodeContext = createContext<any>(initialState);

let persistedState: any;

try {
  let persistedStateLocal = JSON.parse(window.localStorage['promocodes'])

  if (persistedStateLocal != null) {
    // Guard logic
    if (persistedStateLocal.codes.length !== 0) {
      let codes: Promocode[] = persistedStateLocal.codes;
      codes = codes.filter((x) => new Date(x.expiresAt) > new Date());
  
      persistedState = { codes };
    }
  } else {
    persistedState = {};
  }
} catch(err) {
  persistedState = {};
}

/**
 * Reducer to controll state flow
 * @param {} state 
 * @param {*} action 
 * @returns 
 */
let reducer = (state: any, action: any) => {
  switch(action.type) {
    case "SET_CODES" : {
      // Ensure new state is persisted
      window.localStorage.setItem(
        'promocodes',
        JSON.stringify({
          codes: action.payload,
        })
      );

      // Set token to localstorage
      window.localStorage.setItem('token', action.payload.token);
      return { ...initialState, isLoggedIn: true, userId: action.payload.userId, userDetails: action.payload.userDetails, token: action.payload.token,}
    }
    case "ADD_NEW_CODE": {
      // Check if promocode doesn't exist already.
      let found: boolean = false;
      for(let i = 0; i < state.codes.length; i += 1) {
        const curr: Promocode = state.codes[i];

        if  (curr.promo_code === action.payload) {
          found = true;
          break;
        }
      }

      if (found) return; 
      window.localStorage.setItem('promocodes', JSON.stringify([...state.codes, action.payload]));

      return {
        ...state,
        codes: [...state.codes, action.payload]
      }
    }

    case "REMOVE_CODE": {
      let local: any[] = state.codes;

      local = local.filter((x: Promocode, i: number) => x.promo_code !== action.payload);
      window.localStorage.setItem('promocodes', JSON.stringify(local));
      return {...state, codes: local };
    }

    default: 
      return state;
  }
};

/**
 * Auth provider context
 * @param {*} props 
 * @returns 
 */
function PromocodeContextProvider(props: any) {
  const fullInitialState = {
    ...initialState,
    // Account for persisting state
    ...persistedState
  }

  let [state, dispatch] = useReducer(reducer, fullInitialState);
  let value = { state, dispatch };

  /**
   * Always persist auth state on change
   */
  useEffect(() => {
    window.localStorage['promocodes'] = JSON.stringify({
      codes: [...state.codes]
    })
  }, [state]);
  /**
   * Return provider component
   */
  return (
    <PromocodeContext.Provider value={value}>{props.children}</PromocodeContext.Provider>
  );
}

/**
 * Instatiate consumer 
 */
let PromocodeContextConsumer = PromocodeContext.Consumer;

export { PromocodeContextConsumer, PromocodeContextProvider, PromocodeContext };