import {
  IonContent,
  IonFooter,
  IonPage,
  useIonAlert,
  useIonLoading,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import Button from "../components/Button";
import Header from "../components/Header";
import { PRICELISTYPE } from "../constant/constant";
import { Item } from "../models/pricelist.model";
import { deleteWash, fetchPriceList, getWashById } from "../services";
import { OrderDetailLayout } from "../styles/OrderDetailLayout";
import { getTotalString } from "../utils/gettotalString";

const OrderDetail: React.FC = () => {
  const history = useHistory();
  let { id } = useParams<any>();
  const [presentLoading, dismissLoading] = useIonLoading();
  const [presentAlert] = useIonAlert();
  const [wash, setWash] = useState<any>({});
  const [transaction, setTransaction] = useState<any>({});
  const [token, setToken] = useState<any>({});
  const [prices, setPrices] = useState<any>({});
  const [consumables, setConsumables] = useState<any>([]);
  const [hasFetchedPrices, setHasFetchedPrices] = useState<boolean>(false);

  const fetchPrices = async () => {
    try {
      const response: Item[] = await fetchPriceList();

      const consumables: any = {};

      for (let x = 0; x < response.length; x = x + 1) {
        const curr: Item = response[x];

        if (curr.type === PRICELISTYPE.CONSUMABLES) {
          consumables[curr.title.toLowerCase()] = curr;
        }

        if (curr.type === PRICELISTYPE.DETERGENT) {
          consumables[curr.title.toLowerCase()] = curr;
        }
      }



      setPrices(consumables);
      setHasFetchedPrices(true);
    } catch (err) {
      console.log(err);
    }
  };

  const getOrder = async () => {
    presentLoading({ message: "Loading...", backdropDismiss: false });

    try {
      const response = await getWashById(id);
      setWash(response.wash);

      const consumables = JSON.parse(response.wash.consumables_name[0]);
      setConsumables(consumables);

      if (response.transaction) setTransaction(response.transaction);
      else setTransaction(null);

      if (response.token) setToken(response.token);
      else setToken(null);

      console.log(response);
      dismissLoading();
    } catch (err) {
      dismissLoading();
      presentAlert({
        message: "Error! unable to fetch details",
        buttons: [
          {
            text: "Ok",
            handler: () => history.goBack(),
          },
        ],
        backdropDismiss: false,
      });
    }
  };

  useEffect(() => {
    fetchPrices();
    getOrder();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const confirmDeletion = () => {
    presentAlert({
      subHeader: "Are you sure?",
      message:
        "Do you want to proceed to delete this wash order?, This step is irreversible",
      buttons: [
        {
          text: "Cancel",
        },
        {
          text: "Proceed",
          handler: () => deletewash(),
        },
      ],
    });
  };

  const deletewash = async () => {
    presentLoading({ message: "Loading...", backdropDismiss: false });

    try {
      await deleteWash(wash.id);
      history.goBack();
      dismissLoading();
    } catch (err) {
      dismissLoading();
      presentAlert({ message: "Unable to delete wash! " });
    }
  };

  const goBack = () => history.goBack();
  return (
    <IonPage>
      <IonContent style={{ "--background": "#f9f8f9" }}>
        <Header title="Order Detail" onClick={() => goBack()} />

        <OrderDetailLayout>
          <div className="main-content">
            <div className="item">
              {token != null && (
                <div className="flex">
                  <h3>Token</h3>
                  <p> {token.token_id}</p>
                </div>
              )}

              <div className="flex">
                <h3>Status</h3>
                <p> {wash?.status}</p>
              </div>
            </div>

            <div className="item">
              <h2>Wash loads</h2>

              {parseInt(wash.wash_only) > 0 && (
                <div className="flex">
                  <h3>Wash Only</h3>

                  <p>
                    Qty: {wash.wash_only} -{"  "}
                    {getTotalString(wash.wash_only_amount)}
                  </p>
                </div>
              )}

              {parseInt(wash.wash_dry) > 0 && (
                <div className="flex">
                  <h3>Wash & Dry</h3>
                  <p>
                    Qty: {wash.wash_dry} -{"  "}
                    {getTotalString(wash.wash_dry_amount)}
                  </p>
                </div>
              )}

              {parseInt(wash.wash_dry_iron) > 0 && (
                <div className="flex">
                  <h3>Wash, Dry & Iron</h3>
                  <p>
                    Qty: {wash.wash_dry_iron} - {"  "}
                    {getTotalString(wash.wash_dry_iron_amount)}
                  </p>
                </div>
              )}
            </div>

            {hasFetchedPrices && consumables.length > 0 && (
              <>
                <div className="item">
                  <h2>Consumables</h2>
                  {consumables.map((x: { name: string; quantity: number }) => {
                    return (
                      <div className="flex" key={x.name}>
                        <h3>{x.name.toUpperCase()}</h3>

                        <p>
                          Qty: {x.quantity} -{" "}
                          {getTotalString(prices[x.name]?.first_price)}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        </OrderDetailLayout>
      </IonContent>
      <IonFooter>
        <OrderDetailLayout>
          {transaction != null && (
            <>
              <div className="flex-footer">
                <p>Payment Status: </p>
                <p>{transaction.status}</p>
              </div>
              <div className="flex-footer">
                <p>Total</p>
                <p>{getTotalString(transaction.amount)}</p>
              </div>
              <br />
            </>
          )}

          {transaction == null && (
            <div className="pad-btn">
              <Button title="Delete" onClick={() => confirmDeletion()} />
              <br />
            </div>
          )}
        </OrderDetailLayout>
      </IonFooter>
    </IonPage>
  );
};

export default OrderDetail;
