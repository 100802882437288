

import { IonContent, IonList, IonPage, useIonAlert, useIonLoading, useIonToast } from "@ionic/react";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router";
import Button from "../components/Button";
import Header from "../components/Header";
import Input from "../components/Input";
import { changePasswordFromPhone  } from "../services";
import { AuthLayout } from "../styles/AuthLayout";

const ChangePasswordForRecovery: React.FC = () => {
  const history = useHistory();
  const { phone } = useParams<any>();

  const [presentToast ] = useIonToast();
  const [presentLoading, dismissLoading] = useIonLoading();
  const [presentAlert ] = useIonAlert();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");


  const handleGoBack = () => history.goBack();

  const checkPasswordMatch = (): boolean => {
    if(password === confirmPassword) return true;

    return false;
  }

  const handlePasswordChange = (value: any) => {
    setPassword((value.target as HTMLInputElement).value);
  };

  const handleConfirmPasswordchange = (value: any) => {
    setConfirmPassword((value.target as HTMLInputElement).value);
  };


  const handleOnSubmit = async () => {
    if(!checkPasswordMatch()) return presentToast({ message: "Password does not match", duration: 3000 });

    presentLoading({ message: "...Loading", backdropDismiss: false });
    try {
      await changePasswordFromPhone({ new_password: password, phone_number: phone });
      presentToast({ message: "Successfully changed password, Log in with your password", duration: 5000 });

      history.replace('/login');
      dismissLoading();
    } catch(err: any) {
      presentAlert({ subHeader: "Error!", message: "Unable to change password at this time, try again later", buttons: ['Ok'] });
      dismissLoading();
    }
  }

  return (
    <IonPage>
    <IonContent>
      <Header title="" onClick={handleGoBack} />
      <AuthLayout>
        <h1 className="header">Input the otp sent to your phone </h1>
        <p className="excerpt">
          A four digit otp has been sent to your phone as sms. 
        </p>
        <IonList lines="none">
          <Input
            placeholder="New Password"
            type="password"
            onChange={handlePasswordChange}
          ></Input>
          
          <Input
            placeholder="Confirm Password"
            type="password"
            onChange={handleConfirmPasswordchange}
          ></Input>
          <br />

          <div className="spaces"></div>

          <Button title="Confirm OTP" onClick={handleOnSubmit}></Button>
        </IonList>
      </AuthLayout>
    </IonContent>
  </IonPage>
  );
};

export default ChangePasswordForRecovery;
