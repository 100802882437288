import styled from "styled-components";

export const StyledItemBar = styled.div`
  .itembar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .flex-title {
    display: flex;
    align-items: center;

    p {
      margin: 0;
    }

    .itembar-sub {
      color: #a6a6a6;
      font-size: 12px;
      margin: 0px;
      padding-top: 2px;
    }
  }

  .itembar-wrapper {
    padding: 10px;
    margin: 20px 15px;
    background-color: white;
    border-radius: 7px;
    box-shadow: 2px 1px 1px 1px rgba(132,174,45,0.03);
  }

  .itembar-img {
    height: 50px;
    width: 50px;
    object-fit: contain;
    margin: 10px;
  }

  .quantity-bar {
    display: flex;
    width: 90px;
    height: 30px;
    background-color: #E6E6E6;
    border-radius: 30px;
    margin: 5px;
    justify-content: space-between;
    align-items: center;
  }

  .circle-bar {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    margin: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({theme}) => theme.primary};

    .bar-icon {
      color: #fff;
      font-weight: 600;
    }
  }

  p {
    font-size: 14px;
  }

  .dropOffLabel {
    font-size: 14px;
  }

  
`;