import {
  IonContent,
  IonFooter,
  IonPage,
  useIonAlert,
  useIonLoading,
  useIonModal,
} from "@ionic/react";
import React, { useContext } from "react";
import { useHistory } from "react-router";
import Button from "../components/Button";
import Header from "../components/Header";
import ItemBar from "../components/ItemBar";
import PromocodeSelector from "../components/PromocodeSelector";
import { PRICELISTYPE } from "../constant/constant";
import { BillingContext } from "../context/BillingContext";
import { PromocodeContext } from "../context/PromocodeContext";
import { BillingState } from "../models/billing.model";
import { consumables, OrderModel, ORDERTYPE } from "../models/order.model";
import { Item, SHORT_NAMES } from "../models/pricelist.model";
import { CODETYPE, Promocode } from "../models/promocode.model";
import { createWash } from "../services";
import { BillingLayout } from "../styles/BillingLayout";

const Billing: React.FC = () => {
  const history = useHistory();
  const { state, dispatch } = useContext(BillingContext);

  const [presentLoading, dismissLoading] = useIonLoading();
  const [presentAlert] = useIonAlert();

  const onAdd = (i: number, quantity: number) => {
    dispatch({
      type: "MUTATE_QUANTITY",
      payload: { index: i, quantity: quantity + 1 },
    });
  };

  const onSubstract = (i: number, quantity: number) => {
    if (quantity - 1 === 0) return;
    dispatch({
      type: "MUTATE_QUANTITY",
      payload: { index: i, quantity: quantity - 1 },
    });
  };

  const onDropOffChange = (index: number) => {
    const item: Item = state.content[index];
    dispatch({
      type: "DROP_OFF_CHANGE",
      payload: { is_drop_off: !item.is_drop_off, index },
    });
  };

  const onProceed = () => {
    presentAlert({
      message: "Are you sure, you want proceed?",
      buttons: [
        "Cancel",
        { text: "Proceed", handler: (d: any) => processWashOrder() },
      ],
      backdropDismiss: true,
    });
  };

  const processWashOrder = async () => {
    // Display a loader
    presentLoading({ message: "Loading...", backdropDismiss: false });
    const data: OrderModel = processWashData(state);

    try {
      const response = await createWash(data);

      dispatch({
        type: "SET_WASH_ID",
        payload: { id: response.data.id },
      });

      dismissLoading();
      console.log(response);

      history.push("/select-payment");
    } catch (err) {
      dismissLoading();
      presentAlert({
        message: "An error occured, can't proceed to create wash order",
        buttons: ["ok"],
        backdropDismiss: false,
      });
    }
  };

  const processWashData = (data: BillingState): OrderModel => {
    let is_drop_off = false;
    data.content.forEach((x) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      if (x.is_drop_off) is_drop_off = true;
    });

    const body = {
      wash_only_load: 0,
      wash_dry_load: 0,
      wash_dry_iron_load: 0,
      wash_only_amount: 0,
      wash_dry_amount: 0,
      wash_dry_iron_amount: 0,
    };

    let consumables: consumables[] = [];
    let consumable_count = 0;

    data.content.forEach((x) => {
      if (x.type === PRICELISTYPE.WASHES) {
        if (x.short_name === SHORT_NAMES.WASH_ONLY) {
          body.wash_only_load = x.quantity;
          body.wash_only_amount = x.is_drop_off
            ? x.drop_off_first_price +
              (x.quantity - 1) * x.drop_off_second_price
            : x.first_price + (x.quantity - 1) * x.second_price;
        }

        if (x.short_name === SHORT_NAMES.WASH_DRY) {
          body.wash_dry_load = x.quantity;
          body.wash_dry_amount = x.is_drop_off
            ? x.drop_off_first_price +
              (x.quantity - 1) * x.drop_off_second_price
            : x.first_price + (x.quantity - 1) * x.second_price;
        }

        if (x.short_name === SHORT_NAMES.WASH_DRY_IRON) {
          body.wash_dry_iron_load = x.quantity;
          body.wash_dry_iron_amount = x.is_drop_off
            ? x.drop_off_first_price +
              (x.quantity - 1) * x.drop_off_second_price
            : x.first_price + (x.quantity - 1) * x.second_price;
        }
      } else {
        consumable_count += x.quantity;
        consumables.push({
          name: x.title,
          quantity: x.quantity,
        });
      }
    });

    const result: OrderModel = {
      wash_only: body.wash_only_load,
      wash_n_dry: body.wash_dry_load,
      wash_dry_iron: body.wash_dry_iron_load,
      wash_only_amount: body.wash_only_amount,
      wash_dry_amount: body.wash_dry_amount,
      wash_dry_iron_amount: body.wash_dry_iron_amount,
      number_of_consumables: consumable_count,
      name_of_consumables: consumables,
      status: "inactive",
      type_of_order: !is_drop_off ? ORDERTYPE.SELF_SERVICE : ORDERTYPE.DROP_OFF,
      from: "mobile",
      dropoff_location: state.dropoff_location,
      pickup_location: state.pickup_location
    };

    if (state.usePromocode) {
      result.used_promo_codes = 'yes';
      result.PromoCodesId = state.promocodeId;
    }

    return result;
  };

  const onPromocodeSelected = (x: Promocode) => {
    dispatch({ type: "SET_PROMOCODE", payload: x });
    dismissModal();
  };

  const handleModalDismiss = () => {
    dismissModal();
  };

  const [presentModal, dismissModal] = useIonModal(PromocodeSelector, {
    onClick: onPromocodeSelected,
    promocodeContext: useContext(PromocodeContext),
    history: history,
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    dismissModal: handleModalDismiss,
  });

  const removePromocode = () => {
    dispatch({ type: "REMOVE_PROMOCODE" });
    dispatch({ type: "CALCULATE_TOTAL" });
  };

  /**
   * Go back to previous page
   */
  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <BillingLayout>
      <IonPage>
        <IonContent style={{ "--background": "#f9f8f9" }}>
          <Header title="Bill" onClick={() => handleGoBack()} />

          <div className="main-content">
            {state.content.map((x: Item, i: number) => {
              return (
                <ItemBar
                  key={i}
                  title={x.title}
                  price={x.price}
                  image_url={x.image_url}
                  onAdd={() => onAdd(i, x.quantity)}
                  onSubstract={() => onSubstract(i, x.quantity)}
                  type={x.type}
                  onDropOff={() => onDropOffChange(i)}
                  isDropOff={x.is_drop_off}
                  quantity={x.quantity}
                ></ItemBar>
              );
            })}

            <div className="promocode">
              <p>Promocode</p>
              {state.usePromocode && (
                <p>
                  {" "}
                  {state.promocodeType === CODETYPE.AMOUNT ? "NGN" : ""}{" "}
                  {state.promocodeAmount}{" "}
                  {state.promocodeType === CODETYPE.PERCENTAGE ? "%" : ""}
                </p>
              )}

              {state.usePromocode && (
                 <button
                 className="promocode-btn"
                 onClick={() => removePromocode()}
               >
                 Remove
               </button>
              )}

              {!state.usePromocode && (
                <button
                  className="promocode-btn"
                  onClick={() => presentModal()}
                >
                  Select
                </button>
              )}
            </div>
          </div>
        </IonContent>
        <IonFooter mode="md">
          <div className="footer-custom">
            <div className="price-bar">
              <p>Sub Total: </p>
              <p>{state.subTotalDisplay}</p>
            </div>
            <div className="price-bar">
              <p>Total Price: </p>
              <p>{state.totalDisplay}</p>
            </div>
            <Button title="Proceed" onClick={() => onProceed()} />
            <br />
          </div>
        </IonFooter>
      </IonPage>
    </BillingLayout>
  );
};

export default Billing;
