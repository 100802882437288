import {
  IonContent,
  IonList,
  IonPage,
  useIonLoading,
  useIonAlert,
  useIonToast,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import Input from "../components/Input";
import Button from "../components/Button";
import { AuthLayout } from "../styles/AuthLayout";

import { signupService  } from '../services';
import Header from "../components/Header";
import { AuthContext } from "../context/AuthContext";

const SignUp: React.FC = () => {
  const history = useHistory();
  const { state, dispatch } = useContext(AuthContext);

  const [presentLoading, dismissLoading] = useIonLoading();
  const [presentAlert ] = useIonAlert();
  const [presentToast ] = useIonToast();

  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [canProceed, setCanProceed] = useState(false);

  /**
   * Go to OTP screen if user has previously signed
   */
  useEffect(() => {
    if(state.stage === 'OTP_STAGE') {
      history.push('/otp');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const canProceedF = () => {
    if (firstName === "" && firstName == null) {
      presentToast({
        message: "First name is required",
        duration: 3000,
      });
      setCanProceed(false);
      return;
    } else if (lastName === "" && lastName == null) {
      presentToast({
        message: "Last name is required",
        duration: 3000,
      });
      setCanProceed(false);
      return;
    } else if (email === "" && email == null) {
      presentToast({
        message: "Email is required",
        duration: 3000,
      });
      setCanProceed(false);
      return;
    } else if (phone === "" && phone == null) {
      presentToast({
        message: "Phone number is required",
        duration: 3000,
      });
      setCanProceed(false);
      return;
    } else if (password === "" && password == null) {
      presentToast({
        message: "Password is required",
        duration: 3000,
      });
      setCanProceed(false);
      return;
    }
    else {
       setCanProceed(true);
       return;
    }


  };

  useEffect(() => {
    canProceedF();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phone, password, firstName, lastName, email])

  const handleInputChange = (value: any, type: string) => {
    switch (type) {
      case "first_name":
        setFirstName((value.target as HTMLInputElement).value);
        return;
      case "last_name":
        setLastName((value.target as HTMLInputElement).value);
        return;
      case "email":
        setEmail((value.target as HTMLInputElement).value);
        return;
      case "phone":
        setPhone((value.target as HTMLInputElement).value);
        return;
      case "password":
        setPassword((value.target as HTMLInputElement).value);
        return;
      default:
        return null;
    }
  };

  /**
   * Login Button Has been clicked
   */
  const handleOnSubmit = async () => {
    presentLoading({ message: "Loading...", backdropDismiss: false });

    try {
      await signupService({ email, phone, first_name: firstName, last_name: lastName, password, user_type: "user", active: "no", from: "mobile"})
      
      dispatch({ type: 'SET_PHONE',  payload: { phone }});
      history.push('/otp');
      dismissLoading();
    } catch(err) {
      dismissLoading();
      presentAlert({ message: "An error occured while creating your account", buttons: ['ok'], backdropDismiss: true });
    }
  };

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <IonPage>
      <IonContent>
        <Header title="Sign Up" onClick={handleGoBack} />
        <AuthLayout>
          <h1 className="header">Welcome to Folawej 👋🏽</h1>
          <p className="intro-text">
            Hello, I guess you are new around here. We just need a few details
            from you to get you setup
          </p>
          <IonList lines="none">
            <Input
              placeholder="First Name"
              type="text"
              onChange={(e) => handleInputChange(e, "first_name")}
            ></Input>
            <Input
              placeholder="Last Name"
              type="text"
              onChange={(e) => handleInputChange(e, "last_name")}
            ></Input>
            <Input
              placeholder="Email Address"
              type="email"
              onChange={(e) => handleInputChange(e, "email")}
            ></Input>
            <Input
              placeholder="Phone Number"
              type="text"
              onChange={(e) => handleInputChange(e, "phone")}
            ></Input>
            <Input
              placeholder="Password"
              type="password"
              onChange={(e) => handleInputChange(e, "password")}
            ></Input>

            <div className="spaces"></div>

            <Button
              disabled={!canProceed}
              title="Sign Up"
              onClick={() => handleOnSubmit()}
            ></Button>
          </IonList>
        </AuthLayout>
      </IonContent>
    </IonPage>
  );
};

export default SignUp;
