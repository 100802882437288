import { RestApiService } from "./restApiService";


export const checkPromocode = async (code: string) => {
  try {
    const response = await RestApiService.post(`/users/check_promocode`, {
      code: code,
    });
    return response.data;
  } catch(err: any) {
    throw new Error(err);
  }
}