import axios from "axios";
import { PAYSTACK_API_ENDPOINT, PAYSTACK_SK_KEY } from "../constant/constant";

export const refundMoney = async (tx_ref: string) => {
  return await axios.post(
    `${PAYSTACK_API_ENDPOINT}/refund`,
    { transaction: tx_ref },
    {
      headers: {
        authorization: "Bearer " + PAYSTACK_SK_KEY,
        "cache-control": "no-cache",
        "content-type": "application/json",
      },
    }
  );
};
