import styled from "styled-components";

export const StyledButton = styled.div`
  .custom-btn {
    height: 55px;
    background-color: ${({theme}) => theme.primary};
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    border-radius: 7px;
    font-weight: 400;
    width: 100%;
    margin-top: 20px;
    font-size: 17px;
    color: #fff;
  }
`;
