import { IonIcon, IonItem, IonLabel, IonToggle } from "@ionic/react";
import React, { MouseEventHandler } from "react";
import { PRICELISTYPE } from "../constant/constant";
import { StyledItemBar } from "../styles/components/itembar";
import { add, removeOutline } from "ionicons/icons";
import { getTotalString } from "../utils/gettotalString";

interface PriceBarProps {
  title: string;
  onAdd: MouseEventHandler;
  onSubstract: MouseEventHandler;
  type: PRICELISTYPE;
  image_url: string;
  onDropOff?: any;
  isDropOff?: boolean;
  quantity: number;
  price: number;
}

const ItemBar: React.FC<PriceBarProps> = ({
  title,
  onAdd,
  onSubstract,
  type,
  image_url,
  onDropOff,
  isDropOff,
  quantity,
  price,
}) => {
  return (
    <StyledItemBar>
      <div className="itembar-wrapper">
        <div className="itembar">
          <div className="flex-title">
            <img className="itembar-img" src={image_url} alt={title} />
            <div>
              <p>{title}</p>
              <p className="itembar-sub">{getTotalString(price)}</p>
            </div>
          </div>

          {type === PRICELISTYPE.LOGISTICS ? (
            <></>
          ) : (
            <div className="quantity-bar">
              <div className="circle-bar" onClick={onSubstract}>
                <IonIcon className="bar-icon" icon={removeOutline}></IonIcon>
              </div>
              <p className="quantity">{quantity}</p>

              <div className="circle-bar" onClick={onAdd}>
                <IonIcon className="bar-icon" icon={add}></IonIcon>
              </div>
            </div>
          )}
        </div>
        {type === PRICELISTYPE.WASHES ? (
          <div>
            <IonItem lines="none">
              <IonLabel className="dropOffLabel">Drop Off</IonLabel>
              <IonToggle onIonChange={() => onDropOff()} />
            </IonItem>
          </div>
        ) : (
          <></>
        )}
      </div>
    </StyledItemBar>
  );
};

export default ItemBar;
