import {
  IonContent,
  IonLabel,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  useIonViewWillEnter,
} from "@ionic/react";
import React, { useState } from "react";
import { useHistory } from "react-router";
import EmptyState from "../components/EmptyState";
import OrderTile from "../components/OrderTile";
import { OrderTileModel } from "../models/orderTile.model";
import { getWashByStatus } from "../services";
import { TrackLayout } from "../styles/TrackLayout";

const Orders: React.FC = () => {
  const [currentTrack, setCurrentTrack] = useState("all");
  const history = useHistory();

  const [all, setAll] = useState<any>([]);
  const [allPage, setAllPage] = useState(1);
  const [allTotalPage, setAllTotalPage] = useState(null);

  const [pending, setPending] = useState<any>([]);
  const [pendingPage, setPendingPage] = useState(1);
  const [pendingTotalPage, setPendingTotalPage] = useState(null);

  const [completed, setCompleted] = useState<any>([]);
  const [completedPage, setCompletedPage] = useState(1);
  const [completedTotalPage, setCompleteTotalPage] = useState(null);

  const fetchAllOrders = async () => {
    // presentLoading({message: "... Loading", backdropDismiss: false });
    try {
      const response = await getWashByStatus("all", allPage);
      setAll([...all, ...response.data]);
      setAllPage(response?.page);
      setAllTotalPage(response?.pages);
    } catch (err: any) {

      throw new Error(err);
    }
  };

  const fetchPendingOrders = async () => {
    try {
      const response = await getWashByStatus("inprogress", pendingPage);
      setPending([...pending, ...response.data]);
      setPendingPage(response?.page);
      setPendingTotalPage(response?.pages);

    } catch (err: any) {
      throw new Error(err);
    }
  };

  const fetchCompletedOrders = async () => {
    try {
      const response = await getWashByStatus("completed", completedPage);
      setCompleted([...completed, ...response.data]);
      setCompletedPage(response?.page);
      setCompleteTotalPage(response?.pages);
    } catch (err: any) {
      throw new Error(err);
    }
  };

  const disableInfiniteScroll = (): boolean => {
    if (currentTrack === "all" && allPage === allTotalPage) {
      return true;
    }

    if (currentTrack === "in-progress" && pendingPage === pendingTotalPage) {
      return true;
    }

    if (currentTrack === "completed" && completedPage === completedTotalPage) {
      return true;
    }

    return false;
  };

  const searchNext = async () => {
    if (currentTrack === "all") {
      let page: number = allPage;
      const response = await getWashByStatus("all", page + 1);
      setAll([...all, ...response.data]);
      setAllPage(response.page);
      setAllTotalPage(response.pages);
    }

    if (currentTrack === "in-progress") {
      let page: number = pendingPage;
      const response = await getWashByStatus("inprogress", page + 1);
      setPending([...pending, ...response.data]);
      setPendingPage(response.page);
      setPendingTotalPage(response.pages);
    }

    if (currentTrack === "completed") {
      let page: number = completedPage;
      const response = await getWashByStatus("completed", page + 1);
      setCompleted([...completed, ...response.data]);
      setCompletedPage(response.page);
      setCompleteTotalPage(response.pages);
    }
  };

  const goToDetails = (id: string) => {
    history.push("/orders/" + id);
  };

  useIonViewWillEnter(() => {
    fetchAllOrders();
    fetchPendingOrders();
    fetchCompletedOrders();
  })


  return (
    <IonPage>
      <IonContent style={{ "--background": "#f9f8f9" }}>
        <TrackLayout>
          <div className="header-aside">
            <h1>Orders</h1>

            <IonSegment
              mode="ios"
              value={currentTrack}
              className="segment"
              onIonChange={(e) => {
                const value: string = `${e.detail.value}`;
                console.log(e.detail.value);
                setCurrentTrack(value);
              }}
            >
              <IonSegmentButton value="all" className="ion-segment-button">
                <IonLabel className="labels">All</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton
                value="in-progress"
                className="ion-segment-button"
              >
                <IonLabel className="labels">In Progress</IonLabel>
              </IonSegmentButton>

              <IonSegmentButton
                value="completed"
                className="ion-segment-button"
              >
                <IonLabel className="labels">Completed</IonLabel>
              </IonSegmentButton>
            </IonSegment>
          </div>

          <div className="main-content">
            {currentTrack === "all" && (
              <div>
                {all.map((x: OrderTileModel, index: number) => {
                  return (
                    <OrderTile
                      onClick={() => goToDetails(x.id.toString())}
                      id={x.id}
                      key={index}
                      wash_only={x.wash_only}
                      wash_dry={x.wash_dry}
                      wash_dry_iron={x.wash_dry_iron}
                      createdAt={x.createdAt}
                    />
                  );
                })}
              </div>
            )}

            {currentTrack === "all" && all.length === 0 && (
              <EmptyState
                title="NO ORDERS"
                message="Go to home and create a new wash order"
                showButton={false}
                onClick={() => console.log()}
              />
            )}

            {currentTrack === "in-progress" && (
              <div>
                {pending.map((x: OrderTileModel, index: number) => {
                  return (
                    <OrderTile
                      key={index}
                      onClick={() => goToDetails(x.id.toString())}
                      id={x.id}
                      wash_only={x.wash_only}
                      wash_dry={x.wash_dry}
                      wash_dry_iron={x.wash_dry_iron}
                      createdAt={x.createdAt}
                    />
                  );
                })}
              </div>
            )}

            {currentTrack === "in-progress" && pending.length === 0 && (
              <EmptyState
                title="NO ORDERS"
                message="Go to home and create a new wash order"
                showButton={false}
                onClick={() => console.log()}
              />
            )}

            {currentTrack === "completed" && (
              <div>
                {completed.map((x: OrderTileModel, index: number) => {
                  return (
                    <OrderTile
                      key={index}
                      onClick={() => goToDetails(x.id.toString())}
                      id={x.id}
                      wash_only={x.wash_only}
                      wash_dry={x.wash_dry}
                      wash_dry_iron={x.wash_dry_iron}
                      createdAt={x.createdAt}
                    />
                  );
                })}
              </div>
            )}

            {currentTrack === "completed" && completed.length === 0 && (
              <EmptyState
                title="NO ORDERS"
                message="Go to home and create a new wash order"
                showButton={false}
                onClick={() => console.log()}
              />
            )}
          </div>
        </TrackLayout>
        <IonInfiniteScroll
          threshold="100px"
          disabled={disableInfiniteScroll()}
          onIonInfinite={async ($event: CustomEvent<void>) => {
            await searchNext();
            ($event.target as HTMLIonInfiniteScrollElement).complete();
          }}
        >
          <IonInfiniteScrollContent loadingText="Loading more..."></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </IonContent>
    </IonPage>
  );
};

export default Orders;
