import { IonContent, IonPage, useIonAlert, useIonLoading } from "@ionic/react";
import React, { useContext } from "react";
import { useHistory } from "react-router";
import Button from "../components/Button";
import Header from "../components/Header";
import { PAYMENTTYPES } from "../constant/constant";
import { BillingContext } from "../context/BillingContext";
import { createTransaction } from "../services";

const CashPayment: React.FC = () => {
  const history = useHistory();
  const [presentLoading, dismissLoading] = useIonLoading();
  const [presentAlert] = useIonAlert();

  const { state } = useContext(BillingContext);

  const handleGoBack = () => {
    history.goBack();
  };

  const completeCashPaymentOrder = async () => {
    presentLoading({ message: "Finalizing...", backdropDismiss: false });

    try {
      const body = {
        estimated_sum: state.total,
        payment_type: PAYMENTTYPES.CASH,
        wash_id: state.wash_id,
        status: "active",
      };
      
      const response = await createTransaction(body);
      console.log(response);
      dismissLoading();
      // On Success
      history.replace("/home");
    } catch (err) {
      dismissLoading();
      presentAlert({
        message: "Unable to complete transaction",
        buttons: [
          "Cancel",
          {
            text: "Retry",
            handler: () => completeCashPaymentOrder(),
          },
        ],
        backdropDismiss: false,
      });
    }
  };

  return (
    <IonPage>
      <IonContent>
        <Header title="Cash Payment" onClick={handleGoBack}></Header>
        <div style={{ padding: "16px" }}>
          Your Laundry will be confirmed once you make cash payment to one of our agents at Folawej Laundromat.
          <br />
          <div style={{ paddingTop: "40px" }}>
            <Button
              title="Complete"
              onClick={() => completeCashPaymentOrder()}
            ></Button>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default CashPayment;
