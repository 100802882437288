import styled from "styled-components";

export const SettingLayout = styled.div`


  h1 {
    margin-top: 35px;
    font-size: 40px;
    padding: 20px;
  }

  .excerpt {
    font-weight: 300;
    font-size: 14px;
    color: ${({theme}) => theme.grey_400}
  }

  .bg-gray {
    background-color: #d2d2d2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    

    .title {
      font-size: 16px;
    }
  }

  .bg-white {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px 10px 20px;
    color: #000;
    border-bottom: 1px solid #d2d2d2;

    .title {
      font-size: 16px;
    }
  }

  .bg-log-out {
    background-color: '#fff';
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: red;
    padding: 10px 10px 10px 20px;
    p {
      font-size: 16px;
    }

    IonIcon {
      color: red;
      font-size: 50px;
    }
  }

  .w-100 {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  
  .resetPassword {
    margin-top: 20px;
    color: ${({theme}) => theme.primary};
  }
  p {
    font-weight: 200;
  }

  .createAccount {
    color: ${({theme}) => theme.primary};
  }

`;