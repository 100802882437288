import styled from "styled-components";

export const EmptyStateStyled = styled.div`
  .image {
      height: 100px;
      display: block;
      margin: 0 auto;
  }

  .title {
    font-size: 18px;
    text-align: center;
    padding-top: 20px;
  }

  .message {
    text-align: center;
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 20px;
  }
  .box {
    padding: 40px;
  }

  .
`;