import React, { createContext, useEffect, useReducer } from "react";

/**
 * Initial state of user
 */
const initialState = {
  userId: "",
  token: "",
  userDetails: {},
  isLoggedIn: false,
  otp_phone: "",
  stage: "",
};

let AuthContext = createContext<any>(initialState);

let persistedState: any;

try {
  persistedState = JSON.parse(window.localStorage["authState"]) ?? {};
} catch (err) {
  persistedState = {};
}

/**
 * Reducer to controll state flow
 * @param {} state
 * @param {*} action
 * @returns
 */
let reducer = (state: any, action: any) => {
  switch (action.type) {
    case "SET_LOGIN": {
      // Ensure new state is persisted
      window.localStorage.setItem(
        "authState",
        JSON.stringify({
          userId: action.payload.userId,
          token: action.payload.token,
          userDetails: action.payload.userDetails,
          isLoggedIn: true,
        })
      );

      // Set token to localstorage
      window.localStorage.setItem("token", action.payload.token);
      return {
        ...initialState,
        isLoggedIn: true,
        userId: action.payload.userId,
        userDetails: action.payload.userDetails,
        token: action.payload.token,
      };
    }
    case "REMOVE_LOGIN": {
      // Unpersist date
      window.localStorage.removeItem("authState");
      return {
        isLoggedIn: false,
        userId: "",
        token: "",
        userDetails: {},
        otp_phone: "",
      };
    }

    case "SET_PHONE": {
      return { ...state, phone: action.payload.phone, stage: "OTP_STAGE" };
    }

    case "UPDATE_PROFILE": {
      window.localStorage.setItem(
        "authState",
        JSON.stringify({
          userId: state.userId,
          token: state.token,
          userDetails: action.payload,
          isLoggedIn: true,
        })
      );
      return { ...state, userDetails: action.payload };
    }

    case "COMPLETED_CONFIRMATION": {
      return { ...state, state: "" };
    }
    default:
      return state;
  }
};

/**
 * Auth provider context
 * @param {*} props
 * @returns
 */
function AuthContextProvider(props: any) {
  const fullInitialState = {
    ...initialState,
    // Account for persisting state
    ...persistedState,
  };

  let [state, dispatch] = useReducer(reducer, fullInitialState);
  let value = { state, dispatch };

  /**
   * Always persist auth state on change
   */
  useEffect(() => {
    window.localStorage["authState"] = JSON.stringify({
      userId: state.userId,
      userDetails: state.userDetails,
      token: state.token,
      isLoggedIn: state.isLoggedIn,
    });
  }, [state]);
  /**
   * Return provider component
   */
  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
}

/**
 * Instatiate consumer
 */
let AuthContextConsumer = AuthContext.Consumer;

export { AuthContextConsumer, AuthContextProvider, AuthContext };
