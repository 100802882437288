import styled from "styled-components";

export const HomeLayout = styled.div`
  .bg-aside {
    height: 200px;
    width: 100%;
    margin: 0px;
    padding: 50px 20px;
    background-color: ${({ theme }) => theme.primary};
  }

  .sub {
    color: white;
    font-size: 16px;
    font-weight: 300;
    margin: 0px;
    margin-bottom: 5px;
  }

  .name {
    font-size: 20px;
    color: white;
    font-weight: 600;
    text-transform: capitalize;
    margin: 0px;
  }

  .pickanddeliverybar {
    display: flex;
    padding: 2px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 7px;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    margin-top: 20px;
    position: relative;
    overflow: hidden;

    .pickupanddeliverytext {
      color: ${({ theme }) => theme.primary};
      font-size: 16px;
    }

    .icon {
      color: ${({ theme }) => theme.primary};
      font-size: 25px;
    }
  }

  .main-content {
    padding: 10px;
  }

  .dolaundrybar {
    display: flex;
    padding: 16px;
    justify-content: space-between;
    align-items: center;

    .dolaundry-btn {
      background-color: ${({ theme }) => theme.primary};
      padding: 13px 20px;
      border-radius: 7px;
      color: #fff;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.19), 0 3px 3px rgba(0, 0, 0, 0.23);
      width: auto;
    }
  }

  .dolaundry-btn {
    background-color: ${({ theme }) => theme.primary};
    padding: 13px 20px;
    border-radius: 7px;
    color: #fff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.19), 0 3px 3px rgba(0, 0, 0, 0.23);
    width: 90%;
    display: block;
    margin: 0px auto 30px auto;  
  }

  .washes-bar {
    margin-top: 20px;
    margin-bottom: 40px;
    margin-right: 10px;
    margin-left: 10px;
    .washes-title {
      padding: 0px 6px;
      font-size: 14px;
    }
    .washes-box {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
    }
  }
`;
