import React, { MouseEventHandler } from 'react';
import {
  IonIcon,
  isPlatform
} from "@ionic/react";
import { chevronBackOutline } from 'ionicons/icons';
import { StyledHeader } from "../styles/components/header";

interface HeaderProps {
  title: string
  onClick: MouseEventHandler;    
}
const Header: React.FC<HeaderProps> = ({title, onClick}) => {

  return (
    <StyledHeader>
      <div className={`${isPlatform('ios') ? "block-ios" : "block-android"}`}>
        <button className="wrapper" onClick={onClick}>
          <IonIcon icon={chevronBackOutline} className="icon" size="large" style={{fontSize: 36}}></IonIcon>
        </button>

        <h1>{title}</h1>

        <div className="empty-box"></div>
      </div>

    </StyledHeader>

  );
}

export default Header;