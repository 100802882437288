import styled from "styled-components";

export const AddressCollectionView = styled.div`
  padding: 16px;
  height: 100%;
  color: #000000;

  .h2 {
    color: #000000;
  }

  .spaces {
      height: 10px;
  }
`;
