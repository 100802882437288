import { Route, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { setupIonicReact } from '@ionic/react';


import { IonApp } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Theme variables */
import "./theme/variables.css";

import { StatusBar } from '@capacitor/status-bar';


import { theme } from "./constant/theme";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import ResetPassword from "./pages/ResetPassword";
import MainTabs from "./components/MainTab";
import { useContext, useEffect } from "react";
import { AuthContext } from "./context/AuthContext";
import { Toaster } from "react-hot-toast";
import Billing from "./pages/Billing";
import Otp from "./pages/OtpPage";
import { PromocodeContextProvider } from "./context/PromocodeContext";
import Promocodes from "./pages/Promocodes";
import Profile from "./pages/Profile";
import ChangePassword from "./pages/ChangePassword";
import OrderDetail from "./pages/OrderDetail";
import CheckPasswordRecoveryOtp from "./pages/CheckPasswordRecoveryOtp";
import ChangePasswordForRecovery from "./pages/ChangePasswordForRecovery";

/**
 * Must call for some reason Ionic 6 deps
 */
setupIonicReact();
const App: React.FC = () => {
  const { state } = useContext(AuthContext);

  useEffect(() => {
    (async () => {
      try {
        await StatusBar.setBackgroundColor({color: theme.primary });
      } catch(err) {}
    })();
  }, []); 
  return (
    <IonApp>
      <ThemeProvider theme={theme}>
        <PromocodeContextProvider>
          <IonReactRouter>
            <Toaster />
            <Switch>
              <Route path="/login" component={Login} exact={true} />
              <Route path="/register" component={SignUp} exact={true} />

              <Route exact={true} path="/otp" component={Otp} />

              <Route
                exact={true}
                path="/reset-password"
                component={ResetPassword}
              />

              <Route
                exact={true}
                path="/accept-otp/:phone"
                component={CheckPasswordRecoveryOtp}
              />

              <Route
                exact={true}
                path="/change-password/:phone"
                component={ChangePasswordForRecovery}
              />

              {state.isLoggedIn && (
                <Route path="/promocodes" component={Promocodes} exact={true} />
              )}

              {state.isLoggedIn && (
                <Route
                  path="/changepassword"
                  component={ChangePassword}
                  exact={true}
                />
              )}

              {state.isLoggedIn && (
                <Route path="/profile" component={Profile} exact={true} />
              )}

              {state.isLoggedIn && (
                <Route path="/billing" component={Billing} exact={true} />
              )}

              {state.isLoggedIn && (
                <Route
                  path="/orders/:id"
                  component={OrderDetail}
                  exact={true}
                />
              )}

              <Route path="/" component={state.isLoggedIn ? MainTabs : Login} />
            </Switch>
          </IonReactRouter>
        </PromocodeContextProvider>
      </ThemeProvider>
    </IonApp>
  );
};

export default App;
