import {
  IonContent,
  IonIcon,
  IonModal,
  IonPage,
  IonRippleEffect,
  useIonToast,
  useIonViewWillEnter,
} from "@ionic/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { chevronForwardOutline } from "ionicons/icons";
import { useHistory } from "react-router";

import { HomeLayout } from "../styles/HomeLayout";
import { fetchPriceList } from "../services";
import { AuthContext } from "../context/AuthContext";
import { LOGISTICSTYPES, PRICELISTYPE } from "../constant/constant";
import { Item } from "../models/pricelist.model";
import IconBox from "../components/IconBox";
import { theme } from "../constant/theme";
import { BillingContext } from "../context/BillingContext";
import { BillingState } from "../models/billing.model";
import AddressCollection from "../components/AddressCollection";

const useAuth = () => useContext(AuthContext);
const useBilling = () => useContext(BillingContext);

const Home: React.FC = () => {
  const history = useHistory();
  const authState = useAuth();
  const { dispatch } = useBilling();
  const [washesList, setWashesList] = useState<Item[]>([]);
  const [detergentList, setDetergentList] = useState<Item[]>([]);
  const [consumablesList, setConsumableList] = useState<Item[]>([]);
  const [logisticsList, setLogisiticsList] = useState<Item[]>([]);
  const [activeLogisticItem, setActiveLogisticItem] = useState<Item>();
  const [presentToast] = useIonToast();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [pickupAddress, setPickupAddress] = useState<string>("");
  const [deliveryAddress, setDeliveryAddress] = useState<string>("");
  const logisticsContainerRef = useRef<HTMLDivElement>(null);

  const [initialState, setInitialState] = useState<any>({});

  const fetchPrices = async () => {
    try {
      const response: Item[] = await fetchPriceList();

      const washes: Item[] = [];
      const consumables: Item[] = [];
      const detergents: Item[] = [];
      const logistics: Item[] = [];

      for (let x = 0; x < response.length; x = x + 1) {
        const curr: Item = response[x];

        if (curr.type === PRICELISTYPE.WASHES) {
          washes.push({ ...curr, is_active: false, quantity: 1 });
        }

        if (curr.type === PRICELISTYPE.CONSUMABLES) {
          consumables.push({ ...curr, is_active: false, quantity: 1 });
        }

        if (curr.type === PRICELISTYPE.DETERGENT) {
          detergents.push({ ...curr, is_active: false, quantity: 1 });
        }

        if (curr.type === PRICELISTYPE.LOGISTICS) {
          logistics.push({ ...curr, is_active: false, quantity: 1 });
        }
      }

      setInitialState({ washes, detergents, consumables, logistics });

      setWashesList(washes);
      setDetergentList(detergents);
      setConsumableList(consumables);
      setLogisiticsList(logistics);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchPrices();
  }, []);

  useIonViewWillEnter(() => {
    resetActiveStateToInactive();
  });

  const resetActiveStateToInactive = () => {
    /**
     * Reset Each Item in the state
     */
    if (initialState.washes === undefined) return;

    let washes: Item[] = JSON.parse(JSON.stringify(initialState["washes"]));
    washes = washes.map((x) => {
      x.is_active = false;
      return x;
    });

    setWashesList([...washes]);
    /**
     * End of reset washlist Items
     */

    let consumables: Item[] = JSON.parse(
      JSON.stringify(initialState["consumables"])
    );
    consumables = consumables.map((x) => {
      x.is_active = false;
      return x;
    });

    setConsumableList([...consumables]);

    let detergents: Item[] = JSON.parse(
      JSON.stringify(initialState["detergents"])
    );
    detergents = detergents.map((x) => {
      x.is_active = false;
      return x;
    });

    setDetergentList([...detergents]);

    let logistics: Item[] = JSON.parse(
      JSON.stringify(initialState["logistics"])
    );

    logistics = logistics.map((x) => {
      x.is_active = false;
      return x;
    });

    setLogisiticsList([...logistics]);
  };

  const handleSelection = (index: number, type: PRICELISTYPE) => {
    if (type === PRICELISTYPE.WASHES) {
      const oldElement = washesList;
      // Set is active is true
      oldElement[index].is_active = !oldElement[index].is_active;
      // Replace state
      setWashesList([...oldElement]);
    } else if (type === PRICELISTYPE.CONSUMABLES) {
      const oldElement = consumablesList;

      // Set is active is true
      oldElement[index].is_active = !oldElement[index].is_active;
      // Replace state
      setConsumableList([...oldElement]);
    } else if (type === PRICELISTYPE.DETERGENT) {
      const oldElement = detergentList;

      // Set is active is true
      oldElement[index].is_active = !oldElement[index].is_active;
      // Replace state
      setDetergentList([...oldElement]);
    } else {
      let oldElement = logisticsList;

      // Set all to inactive
      if (oldElement[index].is_active) {
        oldElement[index].is_active = !oldElement[index].is_active;
      } else {
        oldElement = oldElement.map((x) => {
          x.is_active = false;
          return x;
        });
        oldElement[index].is_active = !oldElement[index].is_active;
      }

      // Set the active logistic item
      setActiveLogisticItem(oldElement[index]);

      // Replace State
      setLogisiticsList([...oldElement]);
    }
  };

  const WASHES_ACTIVE_COLOR = theme.primary;
  const DETERGENT_ACTIVE_COLOR = theme.detergent_active;
  const CONSUMABLE_ACTIVE_COLOR = theme.consumable_active;
  const LOGISTIC_ACTIVE_COLOR = theme.logistics_active;

  /**
   * Check if user can proceed
   * @returns boolean
   */
  function canProceed(): boolean {
    let isDisabled = true;

    washesList.forEach((x) => {
      if (x.is_active) isDisabled = false;
    });

    detergentList.forEach((x) => {
      if (x.is_active) isDisabled = false;
    });

    consumablesList.forEach((x) => {
      if (x.is_active) isDisabled = false;
    });

    logisticsList.forEach((x) => {
      if (x.is_active) isDisabled = false;
    });

    return isDisabled;
  }

  const pickupandelivery = () => {
    logisticsContainerRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const checkToOpenPickupandDeliveryModal = () => {
    let filterLogisiticsItem = logisticsList.filter((x) => x.is_active);

    if (filterLogisiticsItem.length === 1) {
      setShowModal(true);
    } else {
      proceedToBillingPage();
    }
  };

  const checkIfAddressesHaveBeenFilled = () => {
    if (activeLogisticItem === null || activeLogisticItem === undefined) return;

    const listType = activeLogisticItem.short_name;

    if (listType === LOGISTICSTYPES.PICKUP_AND_DELIVERY) {
      if (
        (pickupAddress === null || pickupAddress === "") ||
        (deliveryAddress === null || deliveryAddress === "")
      ) {
        return presentToast({
          message: "Both pickup and delivery addresses are required",
          duration: 2000,
        });
      }
    } else if (listType === LOGISTICSTYPES.PICKUP_ONLY) {
      if (pickupAddress === null || pickupAddress === "") {
        return presentToast({
          message: "Pickup address is required",
          duration: 2000,
        });
      }
    } else if (listType === LOGISTICSTYPES.DELIVERY_ONLY) {
      if (deliveryAddress === null || deliveryAddress === "") {
        return presentToast({
          message: "Delivery address is required",
          duration: 2000,
        });
      }
    }

    setShowModal(false);

    setTimeout(() => {
      proceedToBillingPage();
    }, 500);

  };

  const proceedToBillingPage = () => {
    const items = [
      ...washesList,
      ...consumablesList,
      ...detergentList,
      ...logisticsList,
    ];
    let filteredItems = items.filter((x) => x.is_active);

    filteredItems = filteredItems.map((x) => {
      if (x.type === PRICELISTYPE.WASHES) {
        return { ...x, is_drop_off: false, price: x.first_price };
      } else {
        return { ...x, price: x.first_price };
      }
    });

    let billingsProps: BillingState = {
      content: filteredItems,
      total: 0,
      totalDisplay: "",
      subTotalDisplay: "",
      subtotal: 0,
      usePromocode: false,
      promocodeId: "",
      promocodeType: "",
      promocodeAmount: "",
    };

    if (activeLogisticItem !== null || activeLogisticItem !== undefined) {
      billingsProps = {
        ...billingsProps,
        pickup_location: pickupAddress,
        dropoff_location: deliveryAddress,
      };
    }
    console.log(billingsProps);
    dispatch({ type: "SET_CONTENT", payload: billingsProps });
    dispatch({ type: "CALCULATE_TOTAL" });

    resetActiveStateToInactive();
    history.push("/billing");
  };

  const getLogisticsType = (): LOGISTICSTYPES => {
    if (activeLogisticItem === null) return LOGISTICSTYPES.PICKUP_ONLY;

    if (activeLogisticItem?.short_name === LOGISTICSTYPES.PICKUP_AND_DELIVERY) {
      return LOGISTICSTYPES.PICKUP_AND_DELIVERY;
    } else if (activeLogisticItem?.short_name  === LOGISTICSTYPES.PICKUP_ONLY) {
      return LOGISTICSTYPES.PICKUP_ONLY;
    } else {
      return LOGISTICSTYPES.DELIVERY_ONLY;
    }
  };

  return (
    <IonPage>
      <IonContent>
        <IonModal
          isOpen={showModal}
          onDidDismiss={() => setShowModal(false)}
          breakpoints={[0.1, 0.6, 1]}
          initialBreakpoint={0.6}
        >
          <AddressCollection
            onButtonClick={checkIfAddressesHaveBeenFilled}
            onPickupAddressChange={(value) =>
              setPickupAddress((value.target as HTMLInputElement).value)
            }
            onDeliveryAddressChange={(value) =>
              setDeliveryAddress((value.target as HTMLInputElement).value)
            }
            type={getLogisticsType()}
          />
        </IonModal>
        <HomeLayout>
          <div className="bg-aside">
            <p className="sub">Welcome back</p>
            <p className="name">{authState.state.userDetails.name}</p>

            <div
              className="pickanddeliverybar ion-activatable"
              onClick={() => pickupandelivery()}
            >
              <p className="pickupanddeliverytext">Pickup and Delivery?</p>
              <IonIcon icon={chevronForwardOutline} className="icon"></IonIcon>
              <IonRippleEffect></IonRippleEffect>
            </div>
          </div>

          <div className="main-content">
            <div className="dolaundrybar">
              <p>Book Laundry</p>
              <div className="dolaundrybutton ion-activatable">
                <button
                  className="dolaundry-btn"
                  onClick={checkToOpenPickupandDeliveryModal}
                  disabled={canProceed()}
                >
                  Do Laundry
                </button>
                <IonRippleEffect></IonRippleEffect>
              </div>
            </div>
            {/**
             * Washes Bar
             */}
            <div className="washes-bar">
              <p className="washes-title">Washes</p>

              <div className="washes-box">
                {washesList.map((wash, index) => {
                  return (
                    <IconBox
                      key={index + "washes"}
                      title={wash.title}
                      onClick={() =>
                        handleSelection(index, PRICELISTYPE.WASHES)
                      }
                      activeBackgroundColor={WASHES_ACTIVE_COLOR}
                      is_active={wash.is_active ?? true}
                      image_url={wash.image_url}
                    ></IconBox>
                  );
                })}
              </div>
            </div>
            {/**
             * End of washes bar
             */}

            {/**
             * Detergent bar
             */}
            <div className="washes-bar">
              <p className="washes-title">Detergents</p>

              <div className="washes-box">
                {detergentList.map((wash, index) => {
                  return (
                    <IconBox
                      key={index + "detergent"}
                      title={wash.title}
                      onClick={() =>
                        handleSelection(index, PRICELISTYPE.DETERGENT)
                      }
                      activeBackgroundColor={DETERGENT_ACTIVE_COLOR}
                      is_active={wash.is_active ?? false}
                      image_url={wash.image_url}
                    ></IconBox>
                  );
                })}
              </div>
            </div>

            {/**
             * End of detergent bar
             */}

            {/**
             * Consumables Bar
             */}
            <div className="washes-bar">
              <p className="washes-title">Consumables</p>

              <div className="washes-box">
                {consumablesList.map((wash, index) => {
                  return (
                    <IconBox
                      key={index + "consumables"}
                      title={wash.title}
                      onClick={() =>
                        handleSelection(index, PRICELISTYPE.CONSUMABLES)
                      }
                      activeBackgroundColor={CONSUMABLE_ACTIVE_COLOR}
                      is_active={wash.is_active ?? false}
                      image_url={wash.image_url}
                    ></IconBox>
                  );
                })}
              </div>
            </div>

            {/**
             * End of consumable bars
             */}

            {/**
             * Logistics Bar
             */}
            <div className="washes-bar" ref={logisticsContainerRef}>
              <p className="washes-title">Logistics</p>

              <div className="washes-box">
                {logisticsList.map((wash, index) => {
                  return (
                    <IconBox
                      key={index + "logistics"}
                      title={wash.title}
                      onClick={() =>
                        handleSelection(index, PRICELISTYPE.LOGISTICS)
                      }
                      activeBackgroundColor={LOGISTIC_ACTIVE_COLOR}
                      is_active={wash.is_active ?? false}
                      image_url={wash.image_url}
                    ></IconBox>
                  );
                })}
              </div>
            </div>
            {/**
             * End of logistics bar
             */}
          </div>

          <div className="dolaundrybutton ion-activatable">
            <button
              className="dolaundry-btn"
              onClick={checkToOpenPickupandDeliveryModal}
              disabled={canProceed()}
            >
              Do Laundry
            </button>
            <IonRippleEffect></IonRippleEffect>
          </div>
        </HomeLayout>
      </IonContent>
    </IonPage>
  );
};

export default Home;
