
export interface Promocode {
  expiresAt: Date;
  promo_code: string;
  code_type: CODETYPE;
  amount: number;
  id: number
};

export const enum CODETYPE {
  AMOUNT = 'amount',
  PERCENTAGE = 'percentage'
}