import {
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";
import React from "react";
import { Redirect, Route } from "react-router";
import { homeOutline, calendarOutline, settingsOutline } from "ionicons/icons";
import Home from "../pages/Home";
import Settings from "../pages/Settings";
import SelectPayment from "../pages/SelectPayment";
import BankTransfer from "../pages/BankTransfer";
import CashPayment from "../pages/CashPayment";
import Orders from "../pages/Orders";

const MainTabs: React.FC = () => {
  return (
    <IonTabs>
      <IonRouterOutlet>
        <Redirect  path="/" to="/home"  exact={true} />
        <Route path="/home" render={() => <Home />}  exact={true} />
        <Route path="/select-payment" render={() => <SelectPayment />} exact={true} />
        <Route path="/bank-transfer" render={() => <BankTransfer/>} exact={true}/>
        <Route path="/cash-payment" render={() => <CashPayment />} exact={true} />
        <Route path='/settings' render={() => <Settings />} exact={true} />
        <Route path='/orders' render={() => <Orders />} exact={true} />
      </IonRouterOutlet>
      <IonTabBar slot="bottom">
        <IonTabButton tab="home" href="/home">
          <IonIcon icon={homeOutline} />
          <IonLabel>Home</IonLabel>
        </IonTabButton>
        <IonTabButton tab="orders" href="/orders">
          <IonIcon icon={calendarOutline} />
          <IonLabel>Orders</IonLabel>
        </IonTabButton>
        <IonTabButton tab="settings" href="/settings">
          <IonIcon icon={settingsOutline} />
          <IonLabel>Settings</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default MainTabs;
