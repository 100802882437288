import styled from "styled-components";

export const AuthLayout = styled.div`
  padding: 20px;

  .content {
    padding: 20px;

    p {
      margin: 0px;
      margin-top: 10px;
      font-size: 14px;
    }
  }
  img {
    width: 130px;
    margin: 30px auto;
    display: block;
  }

  .header {
    color: ${({theme}) => theme.primary};
    font-size: 22px;
    margin-top: 40px;
    margin-bottom: 10px;
  }

  .spaces {
    margin-top: 10px;
  }

  .excerpt {
    font-weight: 300;
    font-size: 14px;
    color: ${({theme}) => theme.grey_400}
  }

  .w-100 {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .intro-text {
    color: #d7d7d7;
    font-size: 14px;
  }
  
  .resetPassword {
    margin-top: 20px;
    color: ${({theme}) => theme.primary};
  }

  p {
    font-weight: 200;
  }

  .createAccount {
    color: ${({theme}) => theme.primary};
  }

`;