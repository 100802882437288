import { IonPage, IonContent, IonRippleEffect, IonIcon, useIonLoading, useIonAlert, useIonToast } from "@ionic/react";
import { chevronForward } from "ionicons/icons";
import React, { useContext } from "react";
import { useHistory } from "react-router";
import Header from "../components/Header";
import { SelectPaymentLayout } from "../styles/SelectPaymentLayout";
import { usePaystackPayment } from 'react-paystack';
import { BillingContext } from "../context/BillingContext";
import { AuthContext } from "../context/AuthContext";
import { PAYMENTTYPES, PAYSTACK_PB_KEY } from "../constant/constant";
import { createTransactionAndUpdate } from "../services";
import { refundMoney } from "../utils/paystack";

const SelectPayment: React.FC = () => {
  const history = useHistory();
  const { state } = useContext(BillingContext);
  const authState = useContext(AuthContext);
  const [presentLoading, dismissLoading] = useIonLoading();
  const [presentAlert ] = useIonAlert();
  const [presentToast] = useIonToast();

  const config = {
    reference: (new Date()).getTime().toString(),
    email: authState.state.userDetails.user_email,
    amount: state.total * 100,
    publicKey: PAYSTACK_PB_KEY
  }

  const initializePayment = usePaystackPayment(config);


  const onSuccess = (reference: any) => {
    finalizeTransaction(reference.reference);
  };

  const onClose = () => {
    console.log('closed');
  }

  const doRefund = async (ref: string) => {
    await refundMoney(ref);
    presentToast({ message: "Refund has been initiated", duration: 3000 });
  }

  /**
   * Go back to previous page
   */
  const handleGoBack = () => {
    history.goBack();
  };

  const finalizeTransaction = async (reference: string) => {
    presentLoading({ message: "Loading...", backdropDismiss: false });
    let body = {
      estimated_sum: state.total,
      payment_type: PAYMENTTYPES.CARD,
      reference_id: reference,
      wash_id: state.wash_id
    };

    try {
      const response = await createTransactionAndUpdate(body);
      dismissLoading();

      presentAlert({
        header: "Successful",
        message: `Your payment via card has been recieved and your laundry code is ${response.data.token_id}`,
        buttons: [{ text: 'Ok', handler: () => history.replace('/home') }]
      });
    } catch(err) {
      dismissLoading();
      presentAlert({
        message: "Unable to complete transaction",
        buttons: [
          {
            text: "Cancel",
            handler: () => doRefund(reference)
          },

          {
            text: "Retry",
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            handler: () => finalizeTransaction(reference),
          },
        ],
        backdropDismiss: false,
      });
    }
  }

  const goToUrl = (url: string) => {
    history.push(url);
  }

  return (
    <SelectPaymentLayout>
      <IonPage>
        <IonContent>
          <Header title="Select Payment" onClick={() => handleGoBack()} />

          <div className="main-content">
            <button className="item" onClick={() => initializePayment(onSuccess, onClose)}>
              <h3>Card</h3>
              <IonIcon icon={chevronForward} size="large"></IonIcon>
              <IonRippleEffect></IonRippleEffect>
            </button>

            <div className="item" onClick={() => goToUrl('/bank-transfer')}>
              <h3>Bank Transfer</h3>
              <IonIcon icon={chevronForward} size="large"></IonIcon>
              <IonRippleEffect></IonRippleEffect>
            </div>

            <div className="item" onClick={() => goToUrl('/cash-payment')}>
              <h3>Cash</h3>
              <IonIcon icon={chevronForward} size="large"></IonIcon>
              <IonRippleEffect></IonRippleEffect>
            </div>
          </div>
        </IonContent>
      </IonPage>
    </SelectPaymentLayout>
  );
};

export default SelectPayment;
