export const theme = {
    // ====== PRIMARY ======
    primary: "#84ae2d",
    primary_50: "#F6FCFE",
    primary_100: "#E8F8FD",
    primary_200: "#D0F1FB",
    primary_300: "#8ADDF4",
    primary_400: "#16BAE9",
    primary_500: "#094A5D",
    primary_600: "#04252F",
  
    //   ===== Grey =======
    grey_50: "#FAFAFA",
    grey_100: "#F2F2F2",
    grey_200: "#E6E6E6",
    grey_300: "#BFBFBF",
    grey_400: "#808080",
    grey_500: "#666666",
    grey_600: "#4D4D4D",
    grey_700: "#333333",
  
    //   ======= Yellow ========
    yellow_50: "#FFFCF5",
    yellow_100: "#FFF8E6",
    yellow_200: "#FFF1CC",
    yellow_300: "#FFDB80",
    yellow_400: "#FDB600",
    yellow_500: "#CC9300",
    yellow_600: "#996E00",
    yellow_700: "#664900",
  
    //   ======= Green =======
    green_50: "#F6FDF8",
    green_100: "#E9FBED",
    green_200: "#D4F7DC",
    green_300: "#93ECA6",
    green_400: "#26D94E",
    green_500: "#1FAD3E",
    green_600: "#17822F",
    green_700: "#0F571F",
  
    //   ======== Red =======
    red_50: "#FEF6F6",
    red_100: "#FCE8E8",
    red_200: "#FAD1D1",
    red_300: "#F28C8C",
    red_400: "#E61A1A",
    red_500: "#B81414",
    red_600: "#8A0F0F",
    red_700: "#5C0A0A",
  
    //   ======= Base =======
    white: "#FFFFFF",
    black: "#000000",

    // ========= Home Selection Colors =====
    detergent_active: "#00aced",
    consumable_active: "#fa6161",
    logistics_active: "#ffff54"
}