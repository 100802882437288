import { PRICELISTYPE } from "../constant/constant";

export interface Item {
  id: number;
  type: PRICELISTYPE;
  title: string;
  image_url: string;
  is_available: true;
  first_price: number;
  second_price: number;
  drop_off_first_price: number;
  drop_off_second_price: number;
  is_active?: boolean;
  quantity: number;
  is_drop_off?: boolean;
  short_name?: string;
  price: number;
}

export const enum SHORT_NAMES {
  WASH_ONLY = 'wash_only',
  WASH_DRY = 'wash_dry',
  WASH_DRY_IRON =  'wash_dry_iron',
}