import React, { createContext, useReducer } from "react";
import { PRICELISTYPE } from "../constant/constant";
import { BillingState } from "../models/billing.model";
import { Item } from "../models/pricelist.model";
import { CODETYPE, Promocode } from "../models/promocode.model";
import { getTotalString } from "../utils/gettotalString";

/**
 * Initial billing state
 */

const initialState: BillingState = {
  total: 0,
  totalDisplay: "",
  subTotalDisplay: "",
  subtotal: 0,
  promocodeId: "",
  promocodeType: "",
  promocodeAmount: "",
  usePromocode: false,
  content: [],
  wash_id: "",
  pickup_location: "",
  dropoff_location: ""
};

let BillingContext = createContext<any>(initialState);

const calculateTotal = (content: Item[]): number => {
  let tempTotal: number = 0;
  for (let x = 0; x < content.length; x = x + 1) {
    const curr: Item = content[x];

    // Check if the item type is washes
    if (curr.type === PRICELISTYPE.WASHES) {
      // Drop off is activated for this price
      if (curr.is_drop_off) {
        tempTotal +=
          curr.drop_off_first_price +
          (curr.quantity - 1) * curr.drop_off_second_price;
      } else {
        tempTotal += curr.first_price + (curr.quantity - 1) * curr.second_price;
      }
    } else {
      tempTotal += curr.quantity * curr.first_price;
    }
  }
  return tempTotal;
};

const calculatePriceForAnItem = (item: Item): number => {
  let price: number = 0;

  if (item.type === PRICELISTYPE.WASHES) {
      if(item.is_drop_off) {
        price = item.drop_off_first_price + (item.quantity - 1) * item.drop_off_second_price;
      } else {
        price = item.first_price + (item.quantity - 1) * item.second_price;
      }
  } else {
    price = item.first_price * item.quantity;
  }

  return price;
};



let reducer = (state: any, action: any) => {
  switch (action.type) {
    case "SET_CONTENT": {
      return { ...initialState, ...action.payload };
    }

    case "CALCULATE_TOTAL": {
      let total = calculateTotal(state.content);

      const totalDisplay = getTotalString(total);
      const subTotalDisplay = getTotalString(total);
      return {
        ...state,
        content: state.content,
        total,
        totalDisplay,
        subTotalDisplay
      };
    }

    case "MUTATE_QUANTITY": {
      const content = state.content;
      const price = calculatePriceForAnItem(content[action.payload.index]);

      content[action.payload.index] = {
        ...content[action.payload.index],
        quantity: action.payload.quantity,
        price,
      };
      const total = calculateTotal(content);
      const totalDisplay = getTotalString(total);
      const subTotalDisplay = getTotalString(total);

      return { ...state, content, total, subtotal: total, totalDisplay, subTotalDisplay };
    }

    case "SET_WASH_ID" : {
      return {...state, wash_id: action.payload.id }
    }

    case "DROP_OFF_CHANGE": {
      const content: Item[] = state.content;
      console.log(action.payload);
      content[action.payload.index] = {
        ...content[action.payload.index],
        is_drop_off: action.payload.is_drop_off,
      };
      const total = calculateTotal(content);
      const totalDisplay = getTotalString(total);
      const subTotalDisplay = getTotalString(total);

      console.log(content);

      return { ...state, content, total, subtotal: total, totalDisplay, subTotalDisplay };
    }

    case "SET_PROMOCODE" : {
      let promocode: Promocode = action.payload;

      let total = state.total;

      if (promocode.code_type === CODETYPE.AMOUNT) {
        total -= promocode.amount;
      };

      if (promocode.code_type === CODETYPE.PERCENTAGE) {
        let total_percentage = total * (promocode.amount / 100);

        total -= total_percentage;
      }

      if (total < 0) total = 0;
      const totalDisplay = getTotalString(total);
      

  
      return { ...state, promocodeId: promocode.id,  promocodeType: promocode.code_type, promocodeAmount: promocode.amount, usePromocode: true, total: total, totalDisplay }
    }

    case "REMOVE_PROMOCODE" : {
      return { ...state, promocodeId: "",  promocodeType: "", promocodeAmount: "", usePromocode: false }
    }

    default:
      return state;
  }
};

/**
 * Billing provider context
 * @param {*} props
 * @return
 */

function BillingContextProvider(props: any) {
  let [state, dispatch] = useReducer(reducer, initialState);
  let value = { state, dispatch };

  /**
   * Return provider component
   *
   */
  return (
    <BillingContext.Provider value={value}>
      {props.children}
    </BillingContext.Provider>
  );
}

/**
 * Instatiate consumer
 */
let BillingContextConsumer = BillingContext.Consumer;

export { BillingContextConsumer, BillingContextProvider, BillingContext };
