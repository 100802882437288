import styled from "styled-components";

export const OrderDetailLayout = styled.div`
  .item {
    padding: 10px 25px 10px 25px;
    margin: 20px 15px;
    background-color: white;
    border-radius: 7px;
    box-shadow: 2px 1px 1px 1px rgba(132, 174, 45, 0.03);
  }

  h2 {
    font-size: 14px;
  }

  .flex {
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    h3 {
      color: #000;
      font-size: 12px;
    }
    p {
      font-size: 12px;
      font-weight: 300;
      text-transform: capitalize;
    }
  }

  .flex-footer {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 5px 20px;


    p {
     font-size: 14px;
      font-weight: 300;
      text-transform: capitalize;
    }
  }

  .pad-btn {
    padding: 16px;
  }
`;
