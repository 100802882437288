export interface OrderModel {
  user_id?: string;
  wash_only: number;
  wash_n_dry: number;
  wash_dry_iron: number;
  wash_only_amount: number;
  wash_dry_amount: number;
  wash_dry_iron_amount: number;
  number_of_consumables: number;
  name_of_consumables: consumables[],
  status: 'inactive',
  type_of_order: ORDERTYPE,
  from: 'mobile',
  PromoCodesId?: number;
  used_promo_codes ?: string,
  pickup_location?: string;
  dropoff_location?: string;
};

export const enum ORDERTYPE {
  SELF_SERVICE = 'self_service',
  DROP_OFF = 'drop_off',
};

export interface consumables {
  name: string;
  quantity: number;
};