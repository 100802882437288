import { RestApiService } from "./restApiService"

interface LoginInterface {
  email: string;
  password: string;
};

export const loginService = async (body: LoginInterface) => {
  try {
    const response = await RestApiService.post('/users/login', body);
    return response.data;
  } catch(err: any) {
    console.log(err);
    throw new Error(err);
  }
};

interface SignUpInterface {
  email: string;
  password: string;
  first_name: string;
  last_name: string;
  phone: string;
  user_type: string;
  active: string;
  from: string;
};

export const signupService = async (body: SignUpInterface) => {
  try {
    const response = await RestApiService.post('/users/register', body);
    return response.data;
  } catch(err: any) {
    console.log(err);
    throw new Error(err);
  }
}

interface ConfirmOtpInterface {
  phone: string;
  token: string;
};
export const confirmOtpToken = async (body: ConfirmOtpInterface) => {
  try {
    const response = await RestApiService.post('/users/confirm_otp', body);
    return response.data;
  } catch(err: any) {
    throw new Error(err);
  }
}

interface updateProfileInterface {
  first_name: string;
  last_name: string;
  phone_number: string;
  unique_id: string;
}

export const profileUpdate = async (body: updateProfileInterface) => {
  try {
    const response = await RestApiService.post('/users/update_profile', body);
    return response.data;
  } catch(err: any) {
    throw new Error(err);
  }
}

interface changePasswordInterface {
  unique_id: string;
  old_password: string;
  new_password: string;
}

export const changePassword = async (body: changePasswordInterface) => {
  try {
    const response = await RestApiService.post(`/users/change_user_password`, body);
    return response.data;
  } catch(err: any) {
    return err;
  }
}

export const getRecoveryOtp = async (phone: string) => {
  try {
    const response = await RestApiService.post('/users/forgot_password', { phone_number: phone });
    return response.data;
  } catch(err: any) {
    return err;
  }
}

export const confirmRecoveryOtp = async (body: any) => {
  try {
    const response = await RestApiService.post('/users/check_forgot_token', body);
    return response.data;
  } catch(err: any) {
    return err;
  }
}

export const changePasswordFromPhone = async (body: any) => {
  try {
    const response = await RestApiService.post('/users/change_password', body);
    return response.data;
  } catch(err: any) {
    return err;
  }
}