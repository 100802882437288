import { IonContent, IonPage } from "@ionic/react";
import React from "react";
import Header from "../components/Header";
import { CODETYPE, Promocode } from "../models/promocode.model";
import { BillingLayout } from "../styles/BillingLayout";
import EmptyState from "./EmptyState";

const PromocodeSelector: React.FC<{
  onClick: Function;
  promocodeContext: any;
  history: any;
  dismissModal: any;
}> = ({ onClick, promocodeContext, history, dismissModal }) => {
  const Items = () => {
    return (
      <>
        {promocodeContext.state.codes.map((x: Promocode, index: number) => {
          return (
            <div className="item-promo" key={index} onClick={() => onClick(x)}>
              <div className="date-wrapper">
                <div className="date">{new Date(x.expiresAt).getDate()}</div>
                <p className="date-details">
                  {new Date(x.expiresAt).getMonth()} -{" "}
                  {new Date(x.expiresAt).getFullYear()}
                </p>
              </div>

              <div>
                <p className="sub">PROMO CODE</p>
                <p className="main">{x.promo_code}</p>
                <br />
                <p className="sub">DISCOUNT</p>
                <p className="main">
                  {x.code_type === CODETYPE.AMOUNT ? "NGN" : ""}
                  {x.amount}
                  {x.code_type === CODETYPE.PERCENTAGE ? "%" : ""}
                </p>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <IonPage>
      <IonContent style={{ "--background": "#f9f8f9" }}>
        <Header onClick={() => dismissModal()} title="" />
        <BillingLayout>
          <div className="select-promo">
            <h3>Select Promocode</h3>
            <p className="promo-select-excert">
              Select a promo code by click on an item.
            </p>
          </div>

          <div className="main-content">
            {promocodeContext.state.codes.length > 0 && <Items />}

            {promocodeContext.state.codes.length === 0 && (
              <EmptyState
                title="NO PROMOCODE"
                message="Go to the promocode page in settings to add a new promocode."
                showButton={false}
                onClick={() => null}
              />
            )}
          </div>
        </BillingLayout>
      </IonContent>
    </IonPage>
  );
};

export default PromocodeSelector;
