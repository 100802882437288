import { PAYMENTTYPES } from "../constant/constant";
import { OrderModel } from "../models/order.model";
import { RestApiService } from "./restApiService";

export const fetchPriceList = async () => {
  try {
    const response = await RestApiService.get('/users/prices');
    return response.data;
  } catch(err) {
    console.log(err);
    return err;
  }
};

export const createWash = async (orderData: OrderModel) => {
  try {
    const user = JSON.parse(window.localStorage['authState']);

    const response = await RestApiService.post('/users/new_wash', {...orderData, user_id: user.userId});
    return response.data;
  } catch(err) {
    console.log(err);
    return err;
  }
}

export const createTransaction = async (body: any) => {
  try {
    const user = JSON.parse(window.localStorage['authState']);

    const response = await RestApiService.post('/users/new_transaction', {...body, user_id: user.userId });
    return response.data;
  } catch(err) {
    console.log(err);
    return err;
  }
}

export const createTransactionAndUpdate = async (body: any) => {
  try {
    const user = JSON.parse(window.localStorage['authState']);
    await RestApiService.post('/users/new_transaction', {...body, user_id: user.userId });

    const response = await RestApiService.post('/users/update_transaction', {...body, trans_type: PAYMENTTYPES.CARD, status: 'completed' });
    return response.data;
  } catch(err) {
    console.log(err);
    return err;
  }
}

export const getWashByStatus = async (status: string, page: number) => {
  try {
      const user = JSON.parse(window.localStorage['authState']);
      const response = await RestApiService.post(`/users/get_wash_by_status?status=${status}&page=${page}`, { user_id: user.userId });
      return response.data;
  } catch(err) {
    console.log(err);
    return err;
  }
}

export const getWashById = async (id: string) => {
  try {
    const response = await RestApiService.get(`/users/wash/${id}`);
    return response.data;
  } catch(err) {
    console.log(err);
    return err;
  }
}

export const deleteWash = async (id: string) => {
  try {
    const response = await RestApiService.get(`users/delete_wash/${id}`);
    return response.data;
  } catch(err) {
    console.log(err);
    return err;
  }
}