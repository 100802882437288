export const enum PRICELISTYPE {
  WASHES = "washes",
  DETERGENT = "detergent",
  CONSUMABLES = "consumables",
  LOGISTICS = "logistics"
};

export const enum ORDERSHORTNAMES {
  WASH_ONLY = 'wash_only',
  WASH_DRY = 'wash_dry',
  WASH_DRY_IRON = 'wash_dry_iron',
}

export const enum PAYMENTTYPES  {
  BANK_TRANSFER = 'Bank Transfer',
  CARD = 'card',
  CASH = 'Cash'
}

export const enum LOGISTICSTYPES {
 PICKUP_ONLY = 'pickup',
 PICKUP_AND_DELIVERY = 'pickup_and_delivery',
 DELIVERY_ONLY = 'delivery'
}

export const PRIVACY_POLICY_URL = "http://folawej.com/privacy-en.html";

export const PAYSTACK_PB_KEY = "pk_live_875795fa34534b559a53db8d7e2256d6a6e9a255";
export const PAYSTACK_SK_KEY = "sk_live_9f6bd7a8e48289a9477535b4cd8cc9f1776f5ec0";

// export const PAYSTACK_PB_KEY = "pk_test_221a7d6171a861c2872a8940d5473443cf8eda70";
// export const PAYSTACK_SK_KEY = "sk_test_2e93660bc007e6827c9bedd0ce59199cc4076c2c";
export const PAYSTACK_API_ENDPOINT = "https://api.paystack.co";
