import {  BASEURL } from '../constant/env';
import axios from 'axios';

/**
 * RestApiService class is to create a wrapper around axios 
 * For easily abstracting over the rest calls 
 */

export class RestApiService {
  // /**
  //  * Turn class into a singleton
  //  */
  // private static instance: RestApiService;

  private constructor() {}

  // public static getInstance(): RestApiService {
  //   if (!RestApiService.instance) {
  //     RestApiService.instance = new RestApiService();
  //   }

  //   return RestApiService.instance;
  // }

  /**
   * Set's Header for auth
   * @returns <Object>
   */
  public static setAuthorizationBearer() {
    const token = window.localStorage.getItem('token');
    const headers = {
      "x-access-token": token?.split("JWT ")[1],
      'Content-Type': 'application/json'
    };
    return headers;
  }
  
  /**
   * Get Method 
   * @param endPoint 
   * @returns 
   */
  public static async get(endPoint: string) {
    const headers = RestApiService.setAuthorizationBearer();
    const response = await axios.get(BASEURL + endPoint, {
      headers: headers,
    });
    return response;
  }

  /**
   * Post Method
   * @param endPoint 
   * @param body 
   * @returns 
   */
  public static async post(endPoint: string, body: any) {
    const headers = RestApiService.setAuthorizationBearer();
    const response = await axios.post(BASEURL + endPoint, body, {
      headers: headers,
    });

    return response;
  }

  /**
   * Patch Method
   * @param endPoint 
   * @param body 
   * @returns 
   */
  public static async patch(endPoint: string, body?: any) {
    const headers = RestApiService.setAuthorizationBearer();
    const response = await axios.patch(BASEURL + endPoint, body, {
      headers: headers,
    });
    return response;
  }

  /**
   * Delete Method
   * @param endPoint 
   * @param body 
   * @returns 
   */
  public static async delete(endPoint: string, body?:any) {
    const headers = RestApiService.setAuthorizationBearer();
    const response = await axios.delete(BASEURL + endPoint, {
      headers: headers
    });
    return response;
  }
}