import {
  IonPage,
  IonContent,
  IonList,
  useIonToast,
  useIonLoading,
  useIonAlert,
  IonButton,
} from "@ionic/react";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router";
import Button from "../components/Button";
import Header from "../components/Header";
import Input from "../components/Input";
import { confirmRecoveryOtp } from "../services";
import { AuthLayout } from "../styles/AuthLayout";

const CheckPasswordRecoveryOtp: React.FC = () => {
  const history = useHistory();
  const { phone } = useParams<any>();
  const [presentToast] = useIonToast();
  const [presentLoading, dismissLoading] = useIonLoading();
  const [presentAlert] = useIonAlert();

  const [otp, setOtp] = useState("");

  const handleGoBack = () => history.goBack();

  const handleOTPChange = (value: any) => {
    setOtp((value.target as HTMLInputElement).value);
  };

  const handleOnSubmit = async () => {
    if (otp === "" || otp === null)
      return presentToast({ message: "Otp cannot be null", duration: 3000 });

    presentLoading({ message: "Loading", backdropDismiss: false });

    try {
      const response = await confirmRecoveryOtp({
        phone_number: phone,
        token: otp,
      });
      console.log(response);
      presentAlert({
        subHeader: "Success",
        message: response.message,
        buttons: [
          {
            text: "Proceed",
            handler: () => goToChangePassword(),
          },
        ],
      });
      dismissLoading();
    } catch (err: any) {
      dismissLoading();
      presentAlert({
        subHeader: "Error",
        message: "Unable to verify otp!",
        buttons: [
          {
            text: "Proceed",
            handler: () => goToChangePassword(),
          },
        ],
      });
    }
  };

  const goToChangePassword = () => {
    history.push("/change-password/" + phone);
  };

  return (
    <IonPage>
      <IonContent>
        <Header title="" onClick={handleGoBack} />
        <AuthLayout>
          <h1 className="header">Input the otp sent to your phone </h1>
          <p className="excerpt">
            A four digit otp has been sent to your phone as sms. 
          </p>
          <IonList lines="none">
            <Input
              placeholder="OTP"
              type="text"
              onChange={handleOTPChange}
            ></Input>

            <br />

            <IonButton expand="block" fill="clear" >Resend</IonButton> 
            <div className="spaces"></div>

            <Button title="Confirm OTP" onClick={handleOnSubmit}></Button>
          </IonList>
        </AuthLayout>
      </IonContent>
    </IonPage>
  );
};

export default CheckPasswordRecoveryOtp;
