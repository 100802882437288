import { IonContent, IonPage } from "@ionic/react";
import React, { useContext } from "react";
import { SettingLayout } from "../styles/SettingLayout";
import { IonIcon } from "@ionic/react";
import { chevronForward, logOutOutline } from "ionicons/icons";
import { useHistory } from "react-router";
import { AuthContext } from "../context/AuthContext";
import { Browser } from "@capacitor/browser";
import { PRIVACY_POLICY_URL } from "../constant/constant";

const TitleHeader: React.FC<{ title: string }> = ({ title }) => {
  return (
    <div className="bg-gray">
      <p className="title">{title}</p>
    </div>
  );
};

const Item: React.FC<{ title: string; url: string; onClick: Function }> = ({
  title,
  url,
  onClick,
}) => {
  return (
    <div className="bg-white" onClick={() => onClick(url)}>
      <p className="title">{title}</p>
      <IonIcon icon={chevronForward} size="large"></IonIcon>
    </div>
  );
};

const Settings: React.FC = () => {
  const history = useHistory();
  const { dispatch } = useContext(AuthContext);
  /**
   * Navigate to URL
   * @param url
   */
  const navigateToUrl = (url: string) => {
    history.push(url);
  };

  const logOut = () => {
    dispatch({
      type: "REMOVE_LOGIN",
    });

    history.replace("/");
  };

  const settingPages = [
    {
      name: "Profile",
      url: "profile",
    },
    {
      name: "Change Password",
      url: "changepassword",
    },
    {
      name: "Promo Codes",
      url: "promocodes",
    },
  ];

  const openPrivacyPolicy = async () => {
    await Browser.open({ url: PRIVACY_POLICY_URL });
  };
  return (
    <SettingLayout>
      <IonPage>
        <IonContent>
          <h1>Settings</h1>

          <TitleHeader title="Profile"></TitleHeader>
          {settingPages.map((x: any, i: number) => {
            return (
              <Item
                key={i}
                title={x.name}
                url={x.url}
                onClick={() => navigateToUrl(x.url)}
              ></Item>
            );
          })}

          <TitleHeader title="Legal"></TitleHeader>
          <Item
            key={settingPages.length}
            title={"Privacy Policy"}
            url=""
            onClick={() => openPrivacyPolicy()}
          ></Item>

          <TitleHeader title="Log Out"></TitleHeader>
          <div className="bg-log-out" onClick={() => logOut()}>
            <p className="title">Log Out</p>
            <IonIcon
              icon={logOutOutline}
              size="large"
              className="log-out-"
            ></IonIcon>
          </div>
        </IonContent>
      </IonPage>
    </SettingLayout>
  );
};

export default Settings;
