import styled from 'styled-components';

export const StyledIconBox = styled.div<{is_active: boolean, activeBg: string}>`

  .icon-box-wrapper {
    width: 100px;
    height: 120px;
    margin: 7px 5px;
    border-radius: 7px;
    background-color ${props => props.is_active ? props.activeBg : "white"};
    transition: background-color 0.5s ease;
    box-shadow: 10px 14px 170px -25px rgba(132,174,45,0.5);
  }

  img {
    height: 50px;
    width: 50px;
    display: block;
    margin: 0 auto;
    object-fit: contain;
  }
  
  .icon-box-wrapper {
    padding: 16px;
  }

  p {
    font-size: 12px;
    text-align: center;
  }
`;