import {
  IonContent,
  IonPage,
  useIonAlert,
  useIonLoading,
  useIonToast,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import Button from "../components/Button";
import Header from "../components/Header";
import Input from "../components/Input";
import { AuthContext } from "../context/AuthContext";
import { profileUpdate } from "../services";
import { AuthLayout } from "../styles/AuthLayout";

const Profile: React.FC = () => {
  const history = useHistory();
  const { state, dispatch } = useContext(AuthContext);

  const [presentToast] = useIonToast();
  const [presentLoading, dismissLoading] = useIonLoading();
  const [presentAlert] = useIonAlert();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [uniqueId, setUniqueId] = useState("");

  useEffect(() => {
    setFirstName(state.userDetails.name.split(" ")[0]);
    setLastName(state.userDetails.name.split(" ")[1]);
    setPhoneNumber(state.userDetails.phone_number);
    setEmailAddress(state.userDetails.user_email);
    setUniqueId(state.userDetails.user_id,);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitProfile = async () => {
    if (firstName === "" && firstName === null) {
      presentToast({
        message: "First name is required",
        duration: 3000,
      });
      return;
    }

    if (lastName === "" && lastName === null) {
      presentToast({
        message: "Last name is required",
        duration: 3000,
      });
      return;
    }

    if (phoneNumber === "" && phoneNumber === null) {
      presentToast({
        message: "Phone number is required",
        duration: 3000,
      });
      return;
    }

    presentLoading({
      message: "Loading...",
      backdropDismiss: false,
    });

    try {
      let data = {
        first_name: firstName,
        last_name: lastName,
        phone_number: phoneNumber,
        unique_id: state.userDetails.user_id,
      };

      const response = await profileUpdate(data);

      dispatch({
        type: "UPDATE_PROFILE",
        payload: response.data,
      });

      presentToast({ message: "Updated Successfully", duration: 3000 });
      dismissLoading();
    } catch (err) {
      dismissLoading();
      presentAlert({
        subHeader: "Error!",
        message: "An error occured, updating profile",
        buttons: ["ok"],
      });
    }
  };

  return (
    <AuthLayout>
      <IonPage>
        <IonContent>
          <Header title="Profile" onClick={() => history.goBack()} />

          <div className="content">
            <p>Unique Id</p>
            <Input
              value={uniqueId}
              placeholder="Unique Id"
              disabled={true}
              onChange={(e) =>
                console.log("null")
              }
            ></Input>

            <p>Email Address</p>
            <Input
              value={emailAddress}
              placeholder="Email Address"
              onChange={(e) =>
                setEmailAddress((e.target as HTMLInputElement).value)
              }
            ></Input>
            <p>First Name</p>
            <Input
              value={firstName}
              placeholder="First Name"
              onChange={(e) =>
                setFirstName((e.target as HTMLInputElement).value)
              }
            ></Input>

            <br />
            <p>Last Name</p>
            <Input
              value={lastName}
              placeholder="Last Name"
              onChange={(e) =>
                setLastName((e.target as HTMLInputElement).value)
              }
            ></Input>

            <br />
            <p>Phone Number</p>
            <Input
              value={phoneNumber}
              placeholder="Phone Number"
              onChange={(e) =>
                setPhoneNumber((e.target as HTMLInputElement).value)
              }
            ></Input>

            <br />

            <Button
              title="Update Profile"
              onClick={() => submitProfile()}
            ></Button>
          </div>
        </IonContent>
      </IonPage>
    </AuthLayout>
  );
};

export default Profile;
