import styled from "styled-components";

export const TrackLayout = styled.div`
  .footer-custom {
    background-color: #fff;
    padding: 16px;

    .price-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        color: #000;
      }
    }
  }

  .header-aside {
    // height: 200px;
    width: 100%;
    margin: 0px;
    padding: 16px;
    background-color: ${({theme}) => theme.primary};

    h1 {
      padding-bottom: 16px;
      padding-left: 0px;
      padding-top: 10px;
      padding-bottom: 0px;
      color: #fff;
    }

    .labels {
      /* color: #; */
      text-transform: capitalize;
    }

    .segment {
      margin-top: 20px;
      margin-bottom: 5px;
    }

    .ion-segment-button {
      --color: #fff;
      --color-checked: ${({theme}) => theme.primary};
      --indicator-color: #fff;
    }
  }


  .main-content {
    padding: constant(safe-area-inset-top) constant(safe-area-inset-right) constant(safe-area- inset-bottom) constant(safe-area-inset-left);

    background-color: #f9f8f9;
    padding-top: 20px;
    padding-bottom: 100px;
    /* min-height: 100vh; */
  }
`;