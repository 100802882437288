import {
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonPage,
  useIonAlert,
  useIonLoading,
} from "@ionic/react";
import { add } from "ionicons/icons";
import React, { useContext } from "react";
import { useHistory } from "react-router";
import EmptyState from "../components/EmptyState";
import Header from "../components/Header";
import { PromocodeContext } from "../context/PromocodeContext";
import { CODETYPE, Promocode } from "../models/promocode.model";
import { checkPromocode } from "../services/promocodes.service";
import { BillingLayout } from "../styles/BillingLayout";

const Promocodes: React.FC = () => {
  const history = useHistory();
  const { state, dispatch } = useContext(PromocodeContext);
  const [presentLoading, dismissLoading] = useIonLoading();
  const [presentAlert ] = useIonAlert();
  // const [code, setCode] = useState('');

  const openPromocodeAlert = () => {
    presentAlert({
      subHeader: "Add Promocode",
      inputs: [
        {
          name: "code",
          type: "number",
          placeholder: "Input code",
        },
      ],
      buttons: [
        {
          text: "Cancel",
        },
        {
          text: "Proceed",
          handler: (data) => {
            addNewPromocode(data.code);
          },
        },
      ],
    });
  };

  const checkCodeExists = (code: string): boolean => {
    let found: boolean = false;
    for (let i = 0; i < state.codes.length; i += 1) {
      let curr: Promocode = state.codes[i];

      if (curr.promo_code === code) {
        found = true;
        break;
      }
    }

    return found;
  };

  const addNewPromocode = async (code: string) => {
    if (checkCodeExists(code)) {
      presentAlert({
        message: "Promocode has already been added",
        buttons: ["ok"],
      });
      return;
    } else {
      presentLoading({ message: "Loading...", backdropDismiss: false });

      try {
        const response = await checkPromocode(code);
        dismissLoading();
        dispatch({ type: "ADD_NEW_CODE", payload: response.data });
      } catch (err: any) {
        console.log('error', err);
        dismissLoading();
        presentAlert({
          message: "Invalid code!",
          buttons: ["ok"],
        });
      }
    }
  };

  const Items = () => {
    return (
      <>
        {state.codes.map((x: Promocode, index: number) => {
          return (
            <div className="item-promo" key={index}>
              <div className="date-wrapper">
                <div className="date">{new Date(x.expiresAt).getDate()}</div>
                <p className="date-details">
                  {new Date(x.expiresAt).getMonth()} -{" "}
                  {new Date(x.expiresAt).getFullYear()}
                </p>
              </div>

              <div>
                <p className="sub">PROMO CODE</p>
                <p className="main">{x.promo_code}</p>
                <br />
                <p className="sub">DISCOUNT</p>
                <p className="main">
                  {x.code_type === CODETYPE.AMOUNT ? "NGN" : ""}
                  {x.amount}
                  {x.code_type === CODETYPE.PERCENTAGE ? "%" : ""}
                </p>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <IonPage>
      <IonContent style={{ "--background": "#f9f8f9" }}>
        <Header title="Promocodes" onClick={() => history.goBack()} />

        <BillingLayout>
          <div className="main-content">
            {state.codes.length === 0 && (
              <EmptyState
                title="NO PROMOCODE"
                message="Click the button to add a new promocode"
                onClick={openPromocodeAlert}
                buttonTitle="Add"
                showButton={true}
              />
            )}

            {state.codes.length > 0 && (
              <IonFab
                onClick={() => openPromocodeAlert()}
                vertical="bottom"
                horizontal="end"
                slot="fixed"
              >
                <IonFabButton>
                  <IonIcon icon={add} />
                </IonFabButton>
              </IonFab>
            )}

            {state.codes.length > 0 && <Items />}
          </div>
        </BillingLayout>
      </IonContent>
    </IonPage>
  );
};

export default Promocodes;
