import styled from "styled-components";

export const SelectPaymentLayout = styled.div`
  padding: 18px;
  .main-content {
    background-color: #f9f8f9;
    padding-top: 20px;
    padding-bottom: 100px;
    min-height: 100%;
  }

  .item {
    padding: 10px 20px 10px 20px;
    margin: 20px 15px;
    background-color: white;
    border-radius: 7px;
    display: flex;
    width: 90%;
    color: #000;
    justify-content: space-between;
    align-items: center;
    box-shadow: 2px 1px 1px 1px rgba(132,174,45,0.03);

    h3 {
      font-size: 16px;

    }
  }
`;