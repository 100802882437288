import styled from "styled-components";

export const InputStyled = styled.div`
.input {
  background-color: #E9EFF5;
  border: none;
  width: 100%;
  padding: 18px 15px;
  margin-top: 15px;
  border-radius: 7px;
  --highlight-color-focused: none;
}

.input:disabled {
  opacity: 0.5;
}

.input:focus-visible {
  border: none;
  box-shadow: none;
  outline: none;
}

.input::placeholder {
  color: rgb(116, 115, 115);
  font-size: 14px;
}
`;
