import React, { MouseEventHandler } from "react";
import { StyledIconBox } from "../styles/components/iconbox";

interface IconBoxProps {
  title: string;
  onClick: MouseEventHandler;
  activeBackgroundColor: string;
  image_url: string;
  is_active: boolean;
}
const IconBox: React.FC<IconBoxProps> = ({
  title,
  onClick,
  activeBackgroundColor,
  image_url,
  is_active,
}) => {
  return (
    <StyledIconBox is_active={is_active} activeBg={activeBackgroundColor}>
      <div className="icon-box-wrapper" onClick={onClick}>
        <div className="icon-box" >
          <img src={image_url} alt={title} />
        </div>
        <p>{title}</p>
      </div>
    </StyledIconBox>
  );
};

export default IconBox;
